import React, { useEffect } from 'react';
import { Select, TitleCard, FieldText, Button, RadioGroup } from '../../ui';
import {
  countriesWithRegions,
  genders,
  nationalities,
  regionsWithCounties,
  titles,
  jobLevelWithGrades,
} from '../../../constants';
import { useForm } from 'react-hook-form';
import { CreateCandidateDto, Payroll, User } from '../../../backend/careo-api';
import { NewCandidateFormContainer } from './new-candidate-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { candidateSchema } from '../../../utils/validators/candidates.validator';
import { handleFormsServerErrors } from '../../../utils/utils';
import { useAuth } from '../../../contexts/auth.context';
import { isCRMApp } from '../../../environment/app.type';
import { EUserRole } from '../../setting/users-list.component';

type NewCandidateFormProps = {
  usersList: User[];
  payrollsList: Payroll[];
  onCancel: () => void;
  onSuccess: () => void;
};

export const NewCandidateForm = ({
  usersList,
  payrollsList,
  onCancel,
  onSuccess,
}: NewCandidateFormProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.ADMIN;

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateCandidateDto>({
    resolver: yupResolver(candidateSchema as any),
  });
  const formValues = watch();

  const recruitersList = usersList.filter((el) =>
    [
      EUserRole.ADMIN,
      EUserRole.RECRUITER,
      EUserRole.RECRUITMENT_OFFICER,
    ].includes(el.role as EUserRole),
  );

  const officersList = usersList.filter((el) =>
    [
      EUserRole.ADMIN,
      EUserRole.OFFICER,
      EUserRole.RECRUITMENT_OFFICER,
    ].includes(el.role as EUserRole),
  );

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.candidates
      .candidatesControllerCreateCandidate({
        ...values,
        whatsappId: values.phoneNumber.replace('+', ''),
      })
      .then(() => {
        toast.success('Candidate added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (user && user.role === 'recruiter') {
      setValue('recruiterId', user._id);
    } else if (user && user.role === 'officer') {
      setValue('recruiterId', user._id);
    }
  }, [user]);

  return (
    <NewCandidateFormContainer>
      {isCRMApp ? (
        <Select
          disabled={!isAdmin}
          placeholder="Select recruiter"
          required
          label={'Recruiter'}
          options={recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          value={!isAdmin ? user?._id : undefined}
          register={register('recruiterId')}
          error={errors.recruiterId}
        />
      ) : (
        <Select
          disabled={!isAdmin}
          placeholder="Select Officer"
          required
          label={'Officer'}
          options={officersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          }))}
          value={!isAdmin ? user?._id : undefined}
          register={register('officerId')}
          error={errors.officerId}
        />
      )}
      <TitleCard>Personal Details</TitleCard>
      <div className="row">
        <div className="col-md-2">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title', { required: true })}
            error={errors.title}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Email"
            required
            register={register('email', { required: true })}
            error={errors.email}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.phoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('phoneNumber', { required: true })}
            error={errors.phoneNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.homePhoneNumber}
            placeholder="+442071234567"
            label="Home Phone Number"
            error={errors.homePhoneNumber}
            register={register('homePhoneNumber')}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.workPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            error={errors.workPhoneNumber}
            register={register('workPhoneNumber')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Date of Birth"
            type="date"
            required
            register={register('birthDate', { required: true })}
            error={errors.birthDate}
            max={new Date().toJSON().split('T')[0]}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Nationality"
            options={nationalities.map((el) => ({ label: el, value: el }))}
            required
            register={register('nationality', { required: true })}
            error={errors.nationality}
          ></Select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Enter here ..."
            label="Gender"
            options={genders.map((el) => ({ label: el, value: el }))}
            required
            register={register('gender', { required: true })}
            error={errors.gender}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="text"
            placeholder="Enter here ..."
            label="NI Number"
            required
            register={register('niNumber', { required: true })}
            error={errors.niNumber}
          />
        </div>
      </div>
      <TitleCard>
        Type of Employment <span style={{ color: 'red' }}>*</span>
      </TitleCard>
      <RadioGroup
        register={register('currentlyEmployed', { required: true })}
        name="currentlyEmployed"
        error={errors.currentlyEmployed}
        options={[
          {
            label: 'Employed',
            value: true,
          },
          {
            label: 'Not Employed',
            value: false,
          },
        ]}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Department"
            required
            register={register('department', { required: true })}
            error={errors.department}
          />
        </div>
        <div className="col-md-6">
          <Select
            placeholder="Select destination"
            label="Designation"
            required
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
            register={register('designation', { required: true })}
            error={errors.designation}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Select
            placeholder="Select grade"
            label="Grade"
            required
            options={
              jobLevelWithGrades
                .find((el) => el.level === formValues.designation)
                ?.grades?.map((el) => ({ label: el, value: el })) ?? []
            }
            register={register('grade', { required: true })}
            error={errors.grade}
            disabled={!formValues.designation}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            step={0.01}
            placeholder="Enter here ..."
            label="Pay Amount"
            required
            type="number"
            register={register('payAmount', { required: true })}
            error={errors.payAmount}
          />
        </div>
      </div>
      {/* address form */}
      <>
        <TitleCard>Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          required
          label="Street Address"
          register={register('address.street', { required: true })}
          error={errors.address?.street}
        />
        <FieldText
          placeholder="Enter here ..."
          required
          label="City"
          register={register('address.city', { required: true })}
          error={errors.address?.city}
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              required
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country', { required: true })}
              error={errors.address?.country}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              required
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region', { required: 'true' })}
              error={errors.address?.region}
              disabled={!formValues.address?.country}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              required
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county', { required: 'true' })}
              error={errors.address?.county}
              disabled={!formValues.address?.region}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              required
              register={register('address.zip', { required: true })}
              error={errors.address?.zip}
            />
          </div>
        </div>
      </>
      <TitleCard>Payroll</TitleCard>
      <RadioGroup
        name="payroll"
        options={[
          {
            label: 'Paye',
            value: 'paye',
          },
        ]}
      />
      <Select
        placeholder="Select Payroll"
        label="Payee options"
        options={[
          ...payrollsList.map((el) => ({
            label: el.payrollProviderName,
            value: el._id,
          })),
        ]}
        required
        register={register('payrollProviderId', { required: true })}
        error={errors.payrollProviderId}
      />
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewCandidateFormContainer>
  );
};
