import React, { useEffect, useState } from 'react';
import {
  CallIcon,
  DownloadIcon,
  DragDropIcon,
  EditIcon,
  LeftArrowIcon,
  MessageIcon,
  UploadIcon,
} from '../../../icons';
import {
  ATag,
  Badge,
  Button,
  CardContainer,
  Table,
  TitlePage,
  UserIcon,
} from '../../../components';
import { PayrollDetailsContainer } from './payroll-details.style';
import {
  AxiosInstance,
  formatDate,
  getDurationDifference,
} from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TUIType } from '../../../constants';
import { Payroll, PayrollExport } from '../../../backend/careo-api';
import { AddressDetails } from '../../../components/address/address-details.component';
import { EModalTypes, SideModal } from '../../../components/modals';
import { UpdatePayrollForm } from '../../../components/payroll/update-payroll-form/update-payroll-form.component';
import ExcelJS from 'exceljs';
import { getStatus } from '../../../utils/status.utils';
import { DocumentsListComponent } from '../../../components/document';
import { DragDropUpload } from '../../../components/drag-drop-upload/drag-drop-upload.component';

enum EPayrollDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
  EXPORTS = 'Exports',
}

const tabs = [
  EPayrollDetailsTabs.GENERAL,
  EPayrollDetailsTabs.CONTACT,
  EPayrollDetailsTabs.PAYROLL,
  EPayrollDetailsTabs.DOCUMENTS,
  EPayrollDetailsTabs.EXPORTS,
];

export const PayrollDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EPayrollDetailsTabs.GENERAL);
  const [payroll, setPayroll] = useState<Payroll | null>(null);
  const [exportsList, setExportsList] = useState<PayrollExport[]>([]);

  const onClickDelete = (id: string | number) => {};
  const onClickView = (id: string | number) => {};

  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();

  const onClickEdit = () => {
    setOpenedModalType(EModalTypes.Update);
  };

  const getPayrollDetails = () => {
    AxiosInstance.payrolls
      .payrollsControllerFindOne(id!)
      .then((response) => {
        setPayroll(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getExports = () => {
    AxiosInstance.payrollExports
      .payrollExportsControllerFindAll({ payrollId: id! })
      .then((response) => {
        setExportsList(response.data as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onGenerateExcel = (data: PayrollExport) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Add data to the worksheet
    worksheet.addRow([
      'CandidateID',
      'Title',
      'Forename',
      'Surname',
      'Timesheet ID',
      'Hours',
      'Pay Rate',
      'On Call Hours',
      'Pay Rate on call',
      'Pay amount',
      'Vat',
      'Payroll provider name',
      'Start Date',
      'End Date',
    ]);

    data.timesheets.map((timesheet) => {
      const candidate = timesheet.placement.candidate;

      const timings = timesheet.timings.map((el) => {
        const [hours1, minutes1] = el.startTime?.split(':') ?? [];
        const [hours2, minutes2] = el.endTime?.split(':') ?? [];
        const timesheetDate = new Date(el.date);
        const resultDate1 = new Date(
          timesheetDate.getFullYear(),
          timesheetDate.getMonth(),
          timesheetDate.getDate(),
          parseInt(hours1),
          parseInt(minutes1),
        );
        const resultDate2 = new Date(
          timesheetDate.getFullYear(),
          timesheetDate.getMonth(),
          timesheetDate.getDate(),
          parseInt(hours2),
          parseInt(minutes2),
        );
        const duration = Number(
          (
            getDurationDifference(resultDate1, resultDate2, el.breakTime) /
            1000 /
            60 /
            60
          ).toFixed(2),
        );

        return {
          duration,
          payAmount: timesheet.placement.job?.payAmount ?? 0,
          lineTotal: (timesheet.placement.job?.payAmount ?? 0) * duration,
        };
      });

      const totalHoursOnCall = Number(
        (
          timesheet.timings.reduce(
            (total, el) => total + (el?.callHours ?? 0),
            0,
          ) /
          (60 * 60)
        ).toFixed(2),
      );

      const rateOnCall = timesheet.placement.onCallRate ?? 0;

      const onCall = {
        totalHours: totalHoursOnCall,
        fee: rateOnCall,
        lineTotal: totalHoursOnCall * rateOnCall,
      };

      const totalNormalHours = timings.reduce(
        (total, el) => total + el.duration,
        0,
      );

      const totalNormalHoursLine = timings.reduce(
        (total, el) => total + el.lineTotal,
        0,
      );
      const totalLine = totalNormalHoursLine + onCall.lineTotal;
      const vat = Number(timesheet.placement?.job.vat ?? 0);

      worksheet.addRow([
        candidate._id,
        candidate.title,
        candidate.firstName,
        candidate.lastName,
        timesheet._id,
        totalNormalHours,
        timesheet.placement.job.payAmount ?? 0,
        onCall.totalHours,
        timesheet.placement.onCallRate ?? 0,
        totalLine,
        Number(((vat * totalLine) / 100).toFixed(2)),
        data.payroll.payrollProviderName,
        formatDate(timesheet.timings[0].date),
        formatDate(timesheet.timings.slice(-1)[0].date),
      ]);

      return timesheet;
    });

    // Generate a blob from the workbook
    workbook.xlsx.writeBuffer().then((buffer: BlobPart) => {
      // Create a Blob from the buffer
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'export-' + data.referenceNumber + '.xlsx';
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getPayrollDetails();
    getExports();
  }, []);

  if (!payroll) {
    return <></>;
  }

  return (
    <>
      <PayrollDetailsContainer>
        <div className="payroll-header" onClick={onClickGoBack}>
          <div className="back-button">
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Payroll Provider</TitlePage>
        </div>
        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              lastName={payroll.payrollProviderName}
              firstName={payroll.payrollProviderName}
              entity="payroll"
            />{' '}
            <div className="payroll-info">
              <div className="name">{payroll.payrollProviderName}</div>
              <div className="availability">{payroll.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{payroll.leadContactWorkPhoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{payroll.leadContactEmail}</div>
          </div>
          <div className="module-contact ">
            <Badge text={'Active'} type={getStatus('Active')} />
          </div>
          <div className="edit-container" onClick={() => onClickEdit()}>
            <EditIcon />
          </div>
        </CardContainer>
        <CardContainer className="payroll-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === EPayrollDetailsTabs.GENERAL && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Business Info</div>
                    <hr />
                    <div className="info-card-content row">
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Name</div>
                        <div className="info-item-data col-8">
                          {payroll.payrollProviderName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Website</div>
                        <div className="info-item-data col-8">
                          {payroll.website}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">VAT Number</div>
                        <div className="info-item-data col-8">
                          {payroll.vatNumber}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Line Manager
                        </div>
                        <div className="info-item-data col-8">
                          {payroll.user?.firstName} {payroll.user?.lastName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Contact Email
                        </div>
                        <div className="info-item-data col-8">
                          {payroll.leadContactEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddressDetails address={payroll.address} />
                </>
              )}
              {selectedTab === EPayrollDetailsTabs.CONTACT && (
                <div className="info-card">
                  <div className="info-card-title">Contact Details</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Title</div>
                      <div className="info-item-data col-8">
                        {payroll.title}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">First Name</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactFirstName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Last Name</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactLastName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Job Title</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactJobTitle}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Email Address</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactEmail}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Mobile Number</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactMobilePhoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Work Phone Number
                      </div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactWorkPhoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Website</div>
                      <div className="info-item-data col-8">
                        {payroll.website}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Fax Number</div>
                      <div className="info-item-data col-8">
                        {payroll.leadContactFax}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EPayrollDetailsTabs.PAYROLL && (
                <div className="info-card">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.name}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.accountName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {payroll.address?.city}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {payroll.employmentType}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {payroll.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EPayrollDetailsTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Payroll Documents</div>
                    <hr />
                    <DocumentsListComponent
                      documents={[]}
                      onClickDelete={() => {}}
                      getDocuments={() => {}}
                    />
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={(files: File[]) => ({} as any)} />
                  </div>
                </>
              )}
              {selectedTab === EPayrollDetailsTabs.EXPORTS && (
                <Table>
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Type</th>
                      <th>Generated</th>
                      <th>Duration</th>
                      <th>Status</th>
                      <th className="action-th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exportsList.map((el) => (
                      <tr key={el._id}>
                        <td>export-{el.referenceNumber}.xlsx</td>
                        <td>xlsx</td>
                        <td>{formatDate(el.createdDate)}</td>
                        <td>7 days</td>
                        <td>
                          <div className="module-contact ">
                            <Badge
                              text={el.status}
                              type={getStatus('Active')}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="action-item">
                            <div
                              className="download-icon"
                              onClick={() => onGenerateExcel(el)}
                            >
                              <DownloadIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </CardContainer>
      </PayrollDetailsContainer>
      <SideModal
        isOpen={openedModalType !== undefined}
        setIsOpen={() => setOpenedModalType(undefined)}
        title={'Update Payroll'}
      >
        <UpdatePayrollForm
          onCancel={() => setOpenedModalType(undefined)}
          onSuccess={() => {
            getPayrollDetails();
            setOpenedModalType(undefined);
          }}
          payrollIdToUpdate={id!}
        />
      </SideModal>
    </>
  );
};
