import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  NewPayrollForm,
  SearchInput,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  AxiosInstance,
  ERoute,
  TFilterPayroll,
  filterPayrolls,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortPayrolls,
  uploadPayrollsCsvRequest,
} from '../../../utils';
import { EModalTypes, SideModal } from '../../../components/modals';
import { useNavigate } from 'react-router-dom';
import { Payroll } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { UpdatePayrollForm } from '../../../components/payroll/update-payroll-form/update-payroll-form.component';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { HeaderPageContainer } from '../../../components/header/header.style';
import { getStatus } from '../../../utils/status.utils';
import { UploadButton } from '../../../components/ui/buttons';

const numberOfItems = 8;

export const PayrollsListPage = () => {
  const navigate = useNavigate();

  const [payrolls, setPayrolls] = useState<Payroll[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState<TFilterPayroll>({
    search: '',
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [payrollIdToUpdate, setPayrollIdToUpdate] = useState<string>();
  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();
  const [recordToDelete, setRecordToDelete] = useState<Payroll>();

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickEdit = (id: string) => {
    setOpenedModalType(EModalTypes.Update);
    setPayrollIdToUpdate(id);
  };

  const onClickCreate = () => {
    setOpenedModalType(EModalTypes.Create);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PAYROLLS}/${id}`);
  };

  const uploadPayrollsCsv = async (file: File) => {
    await uploadPayrollsCsvRequest(file)
      .then(() => {
        getPayrolls();
        toast.success('Payrolls uploaded successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getPayrolls = async () => {
    try {
      const result = (await AxiosInstance.payrolls.payrollsControllerFindAll())
        .data as unknown as Payroll[];
      setPayrolls(result);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const deletePayroll = async () => {
    await AxiosInstance.payrolls
      .payrollsControllerDelete(recordToDelete?._id!)
      .then(() => {
        toast.success('Payroll Removed successfully');
        getPayrolls();
        setRecordToDelete(undefined);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(payrollsList, currentPage, numberOfItems);
  }, [payrollsList, currentPage]);

  useEffect(() => {
    const filteredData = filterPayrolls(payrolls, filter);
    const sortedData = sortPayrolls(filteredData, sort);
    setPayrollsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [payrolls, filter, sort]);

  useEffect(() => {
    getPayrolls();
  }, []);

  return (
    <>
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Payrolls</TitlePage>
            <SubTitlePage>Manage your Payroll Providers</SubTitlePage>
          </div>
          <div className="right-container">
            <UploadButton accept=".csv" onUpload={uploadPayrollsCsv}>
              Upload
            </UploadButton>
            <Button type="primary" onClick={() => onClickCreate()}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search payroll"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <Select options={[]} disabled placeholder="All Offices" />
          <Select options={[]} disabled placeholder="All Job Titles" />
          <Select options={[]} disabled placeholder="All Status" />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th
                  onClick={() => onSelectSort('payrollProviderName', setSort)}
                >
                  <div>
                    <label>Payroll Provider Name</label>
                    <SortIcon
                      value={
                        sort.key === 'payrollProviderName' ? sort.value : ''
                      }
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('leadContactFirstName', setSort)}
                >
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={
                        sort.key === 'leadContactFirstName' ? sort.value : ''
                      }
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('user-firstName', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() =>
                    onSelectSort('leadContactWorkPhoneNumber', setSort)
                  }
                >
                  <div>
                    <label>Phone</label>
                    <SortIcon
                      value={
                        sort.key === 'leadContactWorkPhoneNumber'
                          ? sort.value
                          : ''
                      }
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('vatNumber', setSort)}>
                  <div>
                    <label>VAT Number</label>
                    <SortIcon
                      value={sort.key === 'vatNumber' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('status', setSort)}>
                  <div>
                    <label>Account</label>
                    <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((payroll) => (
                <tr key={payroll._id}>
                  <td className="checkbox-table">
                    <input type="checkbox" />
                  </td>
                  <td>{payroll.payrollProviderName}</td>
                  <td>
                    <div className="name-item">
                      <div>
                        <div>
                          {payroll.leadContactFirstName}{' '}
                          {payroll.leadContactLastName}
                        </div>
                        <div className="email">{payroll.leadContactEmail}</div>
                      </div>
                    </div>
                  </td>
                  <td>{`${payroll.user?.firstName} ${payroll.user?.lastName}`}</td>
                  <td>{payroll.leadContactWorkPhoneNumber}</td>
                  <td>{payroll.vatNumber}</td>
                  <td>
                    <Badge text={'Active'} type={getStatus('Active')} />
                  </td>
                  <td>
                    <div className="action-item">
                      <div
                        className="view-icon"
                        onClick={() => onClickView(payroll._id)}
                      >
                        <EyeIcon />
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => onClickEdit(payroll._id)}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() => setRecordToDelete(payroll)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <div className="navigation-container">
            <div
              className={`left-arrow ${currentPage === 1 && 'disabled'}`}
              onClick={() => navigatePagination(currentPage - 1)}
            >
              <LeftArrowIcon />
            </div>
            <div className="pages-list">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ),
              )}
            </div>
            <div
              className={`right-arrow ${
                currentPage === totalPages && 'disabled'
              }`}
              onClick={() => navigatePagination(currentPage + 1)}
            >
              <RightArrowIcon />
            </div>
          </div>
          <div className="information-container">
            Showing {currentPage} to {totalPages} of {payrollsList.length}{' '}
            entries
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={openedModalType !== undefined}
        setIsOpen={() => setOpenedModalType(undefined)}
        title={
          openedModalType === EModalTypes.Create
            ? 'New Payroll'
            : 'Update Payroll'
        }
      >
        {openedModalType === EModalTypes.Create ? (
          <NewPayrollForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getPayrolls();
              setOpenedModalType(undefined);
            }}
          />
        ) : (
          <UpdatePayrollForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getPayrolls();
              setOpenedModalType(undefined);
            }}
            payrollIdToUpdate={payrollIdToUpdate!}
          />
        )}
      </SideModal>
      <ConfirmModal
        isOpen={recordToDelete !== undefined}
        title="Delete Payroll"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deletePayroll()}
      >
        Do you want to delete <b>{recordToDelete?.payrollProviderName}</b>
      </ConfirmModal>
    </>
  );
};
