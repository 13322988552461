import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  NewClientForm,
  SearchInput,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  TUIType,
  getCountiesOfSelectedRegions,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterClient,
  filterClients,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortClients,
  uploadClientsCsvRequest,
} from '../../../utils';
import { EModalTypes, SideModal } from '../../../components/modals';
import { useNavigate } from 'react-router-dom';
import { Client } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { UpdateClientForm } from '../../../components/client/update-client-form/update-client-form.component';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { HeaderPageContainer } from '../../../components/header/header.style';
import { getStatus } from '../../../utils/status.utils';
import { MultipleSelect } from '../../../components/ui/inputs/select/multiple-select.component';
import { UploadButton } from '../../../components/ui/buttons';

const numberOfItems = 8;

export const ClientsListPage = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState<Client[]>([]);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [clientIdToUpdate, setClientIdToUpdate] = useState<string>();
  const [recordToDelete, setRecordToDelete] = useState<Client>();
  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();
  const [filter, setFilter] = useState<TFilterClient>({
    search: '',
    regions: [],
    counties: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickCreate = () => {
    setOpenedModalType(EModalTypes.Create);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CLIENTS}/${id}`);
  };

  const onClickEdit = (id: string) => {
    setOpenedModalType(EModalTypes.Update);
    setClientIdToUpdate(id);
  };

  const uploadClientsCsv = async (file: File) => {
    await uploadClientsCsvRequest(file)
      .then(() => {
        getClients();
        toast.success('Clients uploaded successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerGetAllClients()
      .then((response) => {
        const result = response.data as unknown as Client[];
        setClients(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const deleteClient = async () => {
    await AxiosInstance.clients
      .clientsControllerDeleteClient(recordToDelete?._id!)
      .then(() => {
        toast.success('Client removed successfully');
        getClients();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(clientsList, currentPage, numberOfItems);
  }, [clientsList, currentPage]);

  useEffect(() => {
    const filteredData = filterClients(clients, filter);
    const sortedData = sortClients(filteredData, sort);
    setClientsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [clients, filter, sort]);

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Clients</TitlePage>
            <SubTitlePage>Manage your Client</SubTitlePage>
          </div>
          <div className="right-container">
            <UploadButton accept=".csv" onUpload={uploadClientsCsv}>
              Upload
            </UploadButton>
            <Button type="primary" onClick={onClickCreate}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search client"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            placeholder="All Counties"
            options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
              (el) => ({ label: el, value: el }),
            )}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                counties: values ?? [],
              }));
            }}
            disabled={!filter.regions.length && !filter.counties.length}
          />
          <Select options={[]} disabled placeholder="All Status" />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th onClick={() => onSelectSort('clientName', setSort)}>
                  <div>
                    <label>Client Name</label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('firstName', setSort)}>
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('user-firstName', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('county', setSort)}>
                  <div>
                    <label>County</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('region', setSort)}>
                  <div>
                    <label>Region</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('status', setSort)}>
                  <div>
                    <label>Account</label>
                    <SortIcon value={sort.key === 'status' ? sort.value : ''} />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((client) => (
                <tr key={(client as any)._id}>
                  <td className="checkbox-table">
                    <input type="checkbox" />
                  </td>
                  <td>{client.clientName}</td>
                  <td>
                    <div className="name-item">
                      <div>
                        <div>
                          {client.firstName} {client.lastName}
                        </div>
                        <div className="email">{client.email}</div>
                      </div>
                    </div>
                  </td>
                  <td>{`${client.user?.firstName} ${client.user?.lastName}`}</td>
                  <td>{client.address?.county ?? '-'}</td>
                  <td>{client.address?.region ?? '-'}</td>
                  <td>
                    <Badge text={'Active'} type={getStatus('Active')} />
                  </td>
                  <td>
                    <div className="action-item">
                      <div
                        className="view-icon"
                        onClick={() => onClickView((client as any)._id)}
                      >
                        <EyeIcon />
                      </div>
                      <div
                        className="edit-icon"
                        onClick={() => onClickEdit((client as any)._id)}
                      >
                        <EditIcon />
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() => setRecordToDelete(client)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <div className="navigation-container">
            <div
              className={`left-arrow ${currentPage === 1 && 'disabled'}`}
              onClick={() => navigatePagination(currentPage - 1)}
            >
              <LeftArrowIcon />
            </div>
            <div className="pages-list">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ),
              )}
            </div>
            <div
              className={`right-arrow ${
                currentPage === totalPages && 'disabled'
              }`}
              onClick={() => navigatePagination(currentPage + 1)}
            >
              <RightArrowIcon />
            </div>
          </div>
          <div className="information-container">
            Showing {currentPage} to {totalPages} of {clientsList.length}{' '}
            entries
          </div>
        </div>
      </CardContainer>

      <SideModal
        isOpen={openedModalType !== undefined}
        setIsOpen={() => setOpenedModalType(undefined)}
        title={
          openedModalType === EModalTypes.Create
            ? 'New Client'
            : 'Update Client'
        }
      >
        {openedModalType === EModalTypes.Create ? (
          <NewClientForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getClients();
              setOpenedModalType(undefined);
            }}
          />
        ) : (
          <UpdateClientForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getClients();
              setOpenedModalType(undefined);
            }}
            clientIdToUpdate={clientIdToUpdate!}
          />
        )}
      </SideModal>

      <ConfirmModal
        isOpen={recordToDelete !== undefined}
        title="Delete Client"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteClient()}
      >
        Do you want to delete <b>{recordToDelete?.clientName}</b>
      </ConfirmModal>
    </>
  );
};
