import React, { useEffect, useMemo, useState } from 'react';
import {
  CallIcon,
  DragDropIcon,
  EditIcon,
  LeftArrowIcon,
  MessageIcon,
  UploadIcon,
} from '../../../icons';
import {
  ATag,
  AddToVacancyForm,
  AvailabilityComponent,
  Button,
  CardContainer,
  ComplianceList,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
} from '../../../components';
import { CandidateDetailsContainer } from './candidate-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  uploadCandidateFile,
  uploadCandidateFiles,
} from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Availability,
  Candidate,
  Compliance,
  HostedFile,
  Job,
  Payroll,
  User,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { EModalTypes, SideModal } from '../../../components/modals';
import { UpdateNoteCandidateForm } from '../../../components/candidate/update-note-candidate-form/update-note-candidate-form.component';
import { CandidateDetailsGeneral } from '../../../components/candidate/candidate-details';
import { DocumentsListComponent } from '../../../components/document/documents-list.component';
import { DragDropUpload } from '../../../components/drag-drop-upload/drag-drop-upload.component';

enum ECandidateDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  AVAILABILITY = 'Availability',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
  COMPLIANCE = 'Compliance',
  NOTES = 'Notes',
}

const tabs = [
  ECandidateDetailsTabs.GENERAL,
  ECandidateDetailsTabs.AVAILABILITY,
  ECandidateDetailsTabs.PAYROLL,
  ECandidateDetailsTabs.DOCUMENTS,
  ECandidateDetailsTabs.COMPLIANCE,
  ECandidateDetailsTabs.NOTES,
];

export const CandidateDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(ECandidateDetailsTabs.GENERAL);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);

  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickGoBack = () => {
    navigate(-1);
  };

  const handleFileChange = async (files: File[]) => {
    await uploadCandidateFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!)
      .then((response) => {
        setCandidate(response as any);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setDocuments(response as any);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getPayrolls = async () => {
    await AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data as unknown as Payroll[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getVacancies = async () => {
    AxiosInstance.jobs
      .jobsControllerGetAllJobs()
      .then((response) => {
        setVacanciesList(response.data as unknown as Job[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getComplianceList = async () => {
    AxiosInstance.compliances
      .compliancesControllerGetAllCompliances({
        candidateId: id,
      })
      .then((response) => {
        setCompliancesList(
          (response.data as unknown as Compliance[]).filter(
            (el) => el.isRequired,
          ),
        );
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getAvailabilities = async () => {
    AxiosInstance.availability
      .availabilityControllerFindAllByCandidateId(id!)
      .then((response) => {
        setAvailabilities(response as unknown as Availability[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getCandidateDetails();
    getUsers();
    getPayrolls();
    getVacancies();
    getCandidateDocuments();
    getComplianceList();
    getAvailabilities();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer>
        <div className="candidate-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Candidate</TitlePage>
        </div>
        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
            />
            <div className="module-info">
              <div className="name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability">{candidate.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container">
            {setOpenedModalType && (
              <Button
                type="default"
                onClick={() => setOpenedModalType(EModalTypes.AddToVacancy)}
              >
                Add to Vacancy
              </Button>
            )}

            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
            >
              Message
            </Button>
          </div>
          {setOpenedModalType && (
            <div
              className="edit-container "
              onClick={() => setOpenedModalType(EModalTypes.Update)}
            >
              <EditIcon />
            </div>
          )}
        </CardContainer>
        <CardContainer className="candidate-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${el === selectedTab && 'active'}`}
                  onClick={() => setSelectedTab(el)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === ECandidateDetailsTabs.GENERAL && (
                <CandidateDetailsGeneral candidate={candidate} />
              )}
              {selectedTab === ECandidateDetailsTabs.AVAILABILITY && (
                <AvailabilityComponent
                  availabilities={availabilities}
                  getAvailabilities={getAvailabilities}
                />
              )}
              {selectedTab === ECandidateDetailsTabs.PAYROLL && (
                <div className="info-card">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {candidate.payrollProvider?.bank?.name}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.address?.city}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.employmentType}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th>Payroll Providers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollsList.map((el) => {
                        return (
                          <tr>
                            <td>
                              <div className="payroll-name-checkbox">
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={
                                    el._id ===
                                    (candidate?.payrollProvider as any)._id
                                  }
                                />
                                <div>{el.payrollProviderName}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === ECandidateDetailsTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Personal Documents</div>
                    <hr />
                    <div className="data-table-container">
                      <DocumentsListComponent
                        documents={documents}
                        onClickDelete={onClickDelete}
                        getDocuments={getCandidateDocuments}
                      />
                    </div>
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={handleFileChange} />
                  </div>
                </>
              )}
              {selectedTab === ECandidateDetailsTabs.COMPLIANCE && (
                <div className="info-card">
                  <div className="info-card-title">Compliances List</div>
                  <hr />
                  <div className="data-table-container">
                    <ComplianceList compliancesList={compliancesList} />
                  </div>
                </div>
              )}
              {selectedTab === ECandidateDetailsTabs.NOTES && (
                <>
                  <div className="info-card">
                    <div className="info-card-header">
                      <div className="info-card-title">Notes Info</div>
                      <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Updates Notes
                      </Button>
                    </div>
                    <hr />
                    <div className="info-card-content">
                      <div className="info-item-title">Notes</div>
                      <div className="info-item-data notes-container">
                        {candidate.notes ?? 'No notes inserted yet'}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardContainer>
      </CandidateDetailsContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Edit Note Candidate"
      >
        <UpdateNoteCandidateForm
          candidate={candidate}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            getCandidateDetails();
            setIsModalOpen(false);
          }}
        />
      </SideModal>

      <SideModal
        isOpen={openedModalType !== undefined}
        setIsOpen={() => setOpenedModalType(undefined)}
        title={
          openedModalType === EModalTypes.AddToVacancy
            ? 'Add to Vacancy'
            : 'Edit Candidate'
        }
      >
        {openedModalType === EModalTypes.AddToVacancy ? (
          <AddToVacancyForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getAvailabilities();
              setOpenedModalType(undefined);
            }}
            candidate={candidate}
            vacancies={vacanciesList}
          />
        ) : (
          <UpdateCandidateForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getCandidateDetails();
              setOpenedModalType(undefined);
            }}
            payrollsList={payrollsList}
            usersList={usersList}
            selectedCandidate={candidate}
          />
        )}
      </SideModal>
    </>
  );
};
