import React, { useEffect, useState } from 'react';
import { Select, TitleCard, FieldText, Button } from '../../ui';
import { jobLevelWithGrades } from '../../../constants';
import { useForm } from 'react-hook-form';
import { Client, Job, User, UpdateJobDto } from '../../../backend/careo-api';
import { UpdateVacancyFormContainer } from './update-vacancy-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { vacancySchema } from '../../../utils/validators/vacancy.validator';
import {
  EJobStatus,
  EPayOption,
} from '../new-vacancy-form/new-vacancy-form.component';

export const UpdateVacancyForm = ({
  onCancel,
  onSuccess,
  vacancy,
  usersList,
  clientsList,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  vacancy: Job;
  usersList: User[];
  clientsList: Client[];
}) => {
  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<UpdateJobDto>({
    resolver: yupResolver(vacancySchema as any),
  });
  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.jobs
      .jobsControllerUpdate(vacancy._id, {
        ...values,
      })
      .then(() => {
        toast.success('Vacancy updated successfully');
        onSuccess();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    reset({
      ...vacancy,
      userId: vacancy.user?._id,
      clientId: vacancy.client?._id,
      vacantFrom: vacancy.vacantFrom?.split?.('T')?.[0] ?? '',
      vacantTo: vacancy.vacantTo?.split?.('T')?.[0] ?? '',
    });
  }, []);

  return (
    <UpdateVacancyFormContainer>
      <Select
        placeholder="Select recruiter"
        required
        label="Recruiter"
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
        control={control}
      />
      <TitleCard>Vacancy Details</TitleCard>
      <Select
        placeholder="Select client"
        label="Client"
        options={[
          ...clientsList.map((el) => ({
            label: el.clientName,
            value: el._id,
          })),
        ]}
        register={register('clientId')}
        error={errors.clientId}
        control={control}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Start Date"
            required
            type="date"
            register={register('vacantFrom')}
            error={errors.vacantFrom}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="End Date"
            required
            type="date"
            register={register('vacantTo')}
            error={errors.vacantTo}
          />
        </div>
      </div>
      <Select
        options={[
          ...jobLevelWithGrades.map((el) => ({
            label: el.level,
            value: el.level,
          })),
        ]}
        placeholder="Select Level"
        label="level"
        required
        register={register('level')}
        error={errors.level}
        control={control}
      />
      <Select
        options={[
          ...(jobLevelWithGrades
            .find((el) => el.level === formValues.level)
            ?.grades?.map((el) => ({ label: el, value: el })) ?? []),
        ]}
        placeholder="Select Grade"
        label="grade"
        required
        register={register('grade')}
        error={errors.grade}
        disabled={!formValues.level}
        control={control}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Specialty"
        required
        register={register('specialty', { required: true })}
        error={errors.specialty}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Job Description"
        required
        register={register('jobDescription', { required: true })}
        error={errors.jobDescription}
      />
      <Select
        options={[
          { label: 'Hired', value: EJobStatus.HIRED },
          { label: 'Pending', value: EJobStatus.PENDING },
          { label: 'Active', value: EJobStatus.ACTIVE },
          { label: 'Closed', value: EJobStatus.CLOSED },
        ]}
        placeholder="Select Vacancy Status"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
        control={control}
      />
      <TitleCard>Pay</TitleCard>
      <Select
        options={[
          { label: 'Time', value: EPayOption.Time },
          { label: 'Fixed', value: EPayOption.Fixed },
        ]}
        placeholder="Select Pay Option"
        label="Option"
        required
        register={register('payOption', { required: true })}
        error={errors.payOption}
        control={control}
      />
      {/* <FieldText placeholder="Enter here ..." label="Type" required /> */}
      {/* <FieldText placeholder="Enter here ..." label="Pay" required /> */}
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Pay amount (per hour)"
            required
            register={register('payAmount', { required: true })}
            error={errors.payAmount}
            type="number"
            min={0}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Hours per week"
            required
            register={register('hoursPerWeek', { required: true })}
            error={errors.hoursPerWeek}
            type="number"
            min={0}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="VAT"
            required
            register={register('vat', { required: true })}
            error={errors.vat}
            type="number"
            min={0}
          />
        </div>
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateVacancyFormContainer>
  );
};
