import axios from 'axios';
import { getToken } from './token.utils';
import { HostedFile } from '../backend/careo-api';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

export const uploadCandidateFile = async (candidateId: string, file: File) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(
      `${API_URL}/files/upload/candidates/${candidateId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const uploadCandidateFiles = async (
  candidateId: string,
  files: File[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();

    files.forEach((file, index) => {
      formData.append(`files`, file);
    });

    const response = await axios.post(
      `${API_URL}/files/upload-multiple/candidates/${candidateId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const sendEmailRequest = async (
  recipient: string,
  recipientType: string,
  subject: string,
  body: string,
  files: File[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('recipient', recipient);
    formData.append('recipientType', recipientType);
    formData.append('subject', subject);
    formData.append('body', body);
    files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await axios.post(
      `${API_URL}/emails/${process.env.REACT_APP_NAME!}/send-email`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const draftEmailRequest = async (
  recipient: string,
  recipientType: string,
  subject: string,
  body: string,
  files: File[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('recipient', recipient);
    formData.append('recipientType', recipientType);
    formData.append('subject', subject);
    formData.append('body', body);
    files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await axios.post(
      `${API_URL}/emails-drafts/${process.env.REACT_APP_NAME!}/create`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response?.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const updateDraftEmailRequest = async (
  id: string,
  recipient: string,
  recipientType: string,
  subject: string,
  body: string,
  files: File[],
  hostedFiles: HostedFile[],
) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('recipient', recipient);
    formData.append('recipientType', recipientType);
    formData.append('subject', subject);
    formData.append('body', body);
    formData.append('hostedFiles', JSON.stringify(hostedFiles));

    files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await axios.patch(
      `${API_URL}/emails-drafts/${process.env.REACT_APP_NAME!}/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const sendWhatsappRequest = async (
  recipientId: string,
  recipientType: 'client' | 'candidate',
  message: string,
  file?: File,
) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('recipientId', recipientId);
    formData.append('recipientType', recipientType);
    formData.append('message', message);

    if (file) {
      formData.append('file', file);
    }

    const response = await axios.post(
      `${API_URL}/whatsapp/${process.env.REACT_APP_NAME}/send-message`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    toast.error('Something went wrong');
  }
};

export const uploadPayrollsCsvRequest = async (file: File) => {
  if (!file) {
    return;
  }

  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(
    `${API_URL}/payrolls/upload-csv`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const uploadClientsCsvRequest = async (file: File) => {
  if (!file) {
    return;
  }

  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${API_URL}/clients/upload-csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const uploadVacanciesCsvRequest = async (file: File) => {
  if (!file) {
    return;
  }

  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${API_URL}/jobs/upload-csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const uploadCandidatesCsvRequest = async (file: File) => {
  if (!file) {
    return;
  }

  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(
    `${API_URL}/candidates/upload-csv`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const uploadUsersCsvRequest = async (file: File) => {
  if (!file) {
    return;
  }

  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(`${API_URL}/users/upload-csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
