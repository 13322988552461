import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file/select-file-list.component';
import { SideModal } from '../../modals';
import { DocumentsListComponent } from '../../document/documents-list.component';

type VerifiedIdentificationProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'ids' | 'driverLicense' | 'proofAddress';

export const VerifiedIdentification = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: VerifiedIdentificationProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([
    { key: 'ids', files: [] },
    { key: 'driverLicense', files: [] },
    { key: 'proofAddress', files: [] },
  ]);
  const { register, getValues, setValue } = useForm<{
    ids: any[];
    driverLicense: any[];
    proofAddress: any[];
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'ids' | 'driverLicense' | 'proofAddress',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const ids = (compliance?.metadata as any)?.ids ?? [];
      const driverLicense = (compliance?.metadata as any)?.driverLicense ?? [];
      const proofAddress = (compliance?.metadata as any)?.proofAddress ?? [];

      setValue('ids', ids);
      setValue('driverLicense', driverLicense);
      setValue('proofAddress', proofAddress);
      setFilesList([
        { key: 'ids', files: ids },
        { key: 'driverLicense', files: driverLicense },
        { key: 'proofAddress', files: proofAddress },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      <TitleCard>Qualifying Documents</TitleCard>
      <div className="description">
        Qualifying documents = 2 photo ID's. 1 proof of address **Refer to
        appendix 1 via the following web link**
        https://www.nhsemployers.org/publications/identity-checks-standard 
      </div>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="ID Photo"
              onChange={(e) => handleFileChange(e, 'ids')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('ids')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>

        <DocumentsListComponent
          documents={filesList.find((el) => el.key === 'ids')?.files ?? []}
          onClickDelete={(id) => {
            onClickDelete('ids', id);
          }}
          getDocuments={getDocuments}
          onDocumentRename={() => reFetchCompliance?.()}
        />
      </>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Driver License"
              onChange={(e) => handleFileChange(e, 'driverLicense')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('driverLicense')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>

        <DocumentsListComponent
          documents={
            filesList.find((el) => el.key === 'driverLicense')?.files ?? []
          }
          onClickDelete={(id) => {
            onClickDelete('driverLicense', id);
          }}
          getDocuments={getDocuments}
          onDocumentRename={() => reFetchCompliance?.()}
        />
      </>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Proof of Address"
              onChange={(e) => handleFileChange(e, 'proofAddress')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('proofAddress')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>

        <DocumentsListComponent
          documents={
            filesList.find((el) => el.key === 'proofAddress')?.files ?? []
          }
          onClickDelete={(id) => {
            onClickDelete('proofAddress', id);
          }}
          getDocuments={getDocuments}
          onDocumentRename={() => reFetchCompliance?.()}
        />
      </>
      <TitleCard>Administer validity checks for YOTI or Trust ID </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="yoti"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
      />
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
        />
      </SideModal>
    </>
  );
};
