import React, { useEffect, useState } from 'react';
import { FieldText, Button, CardContainer, TitlePage, RadioGroup } from '../ui';
import { PayrollExportSettings } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';
import { daysOfWeekOptions } from '../../constants';
import { EFrequency } from '../campaign/new-campaign-form/new-campaign-form.component';

export const PayrollSetting = () => {
  const [payrollSettings, setPayrollSettings] = useState<PayrollExportSettings>(
    {} as PayrollExportSettings,
  );

  const getPayrollSettings = async () => {
    await AxiosInstance.payrollExports
      .payrollExportsControllerGetPayrollExportSettings()
      .then((response) => {
        if (!response) {
          return;
        }

        const result = response as unknown as PayrollExportSettings;
        setPayrollSettings({
          ...result,
          time: result.time,
        });
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickSubmit = async () => {
    if (!payrollSettings?.frequency || !payrollSettings?.time) {
      toast.error('Please fill the data');
      return;
    }

    await AxiosInstance.payrollExports
      .payrollExportsControllerUpdatePayrollExportSettings({
        ...payrollSettings,
        time: payrollSettings.time,
      })
      .then(() => {
        toast.success('Payroll Settings updated successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    getPayrollSettings();
  }, []);

  return (
    <CardContainer className="right-container">
      <div className="settings-title-content">
        <TitlePage>Payroll</TitlePage>
      </div>
      <hr />
      <div className="settings-content">
        <div className="data-table-container">
          <TitlePage>Select your payroll frequency</TitlePage>
          <br />
          <RadioGroup
            options={[
              {
                label: 'Every Day',
                value: EFrequency.Daily,
                description:
                  'Payroll for each payroll provider will be generated for export every 24hrs. This will be generated based on approved timesheets logged in the system and associated with the payroll provider selected for each candidate.',
              },
              {
                value: EFrequency.Weekly,
                label: 'Weekly',
                description:
                  'Payroll for each payroll provider will be generated for export every 7 days. This will be generated based on approved timesheets logged in the system and associated with the payroll provider selected for each candidate.',
              },
            ]}
            name={'frequencyPayroll'}
            value={payrollSettings?.frequency}
            onChange={(e) =>
              setPayrollSettings((prev: any) => ({
                ...prev,
                frequency: e,
              }))
            }
          />
          <br />
          <div className="days-container">
            {payrollSettings.frequency === EFrequency.Weekly && (
              <RadioGroup
                options={daysOfWeekOptions}
                name={'dayPayroll'}
                onChange={(value) =>
                  setPayrollSettings((prev: any) => ({
                    ...prev,
                    dayOfTheWeek: Number(value),
                  }))
                }
                value={payrollSettings?.dayOfTheWeek}
              />
            )}
            <br />
            <FieldText
              placeholder="Enter here ..."
              label="Time (GMT)"
              required
              type="time"
              onChange={(e) =>
                setPayrollSettings((prev: any) => ({
                  ...prev,
                  time: e.target.value,
                }))
              }
              value={payrollSettings?.time}
            />
          </div>
          <div className="form-actions">
            <Button type="primary" onClick={onClickSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
