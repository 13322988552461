import React, { Dispatch, SetStateAction } from 'react';
import { TitleCard, Button, FieldText } from '../../ui';
import { useForm } from 'react-hook-form';
import { Application, ValidatePlacementDto } from '../../../backend/careo-api';
import { ValidatePlacementFormContainer } from './validate-placement-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { PlacementValidateSchema } from '../../../utils/validators/playcement.validator';
import { yupResolver } from '@hookform/resolvers/yup';

type NewPlacementFormProps = {
  application: Application;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getPlacementDetails: any;
};

export const ValidatePlacementForm = ({
  application,
  setIsOpen,
  getPlacementDetails,
}: NewPlacementFormProps) => {
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ValidatePlacementDto>({
    resolver: yupResolver(PlacementValidateSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerValidatePlacement(application._id, {
        ...values,
      })
      .then(() => {
        getPlacementDetails();
        toast.success('Placement validated successfully');
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <ValidatePlacementFormContainer>
      <TitleCard>Placement Charges</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Charge/Fee (per hour)"
        required
        register={register('fee', { required: true })}
        type="number"
        min={0}
        error={errors.fee}
      />
      <FieldText
        placeholder="Enter here ..."
        label="VAT"
        required
        register={register('vat', { required: true })}
        type="number"
        min={0}
        error={errors.vat}
      />
      <FieldText
        placeholder="Enter here ..."
        label="On Call Rate (per hour)"
        required
        register={register('onCallRate', { required: true })}
        type="number"
        min={0}
        error={errors.onCallRate}
      />
      <FieldText
        placeholder="Enter here ..."
        label="On Call Fee (per hour)"
        required
        register={register('onCallFee', { required: true })}
        type="number"
        min={0}
        error={errors.onCallFee}
      />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Validate
        </Button>
      </div>
    </ValidatePlacementFormContainer>
  );
};
