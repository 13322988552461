import React from 'react';
import { MessageListContainer } from './messages-list.style';
import { UserIcon } from '../../ui';
import { ChatListItemDto } from '../../../backend/careo-api';
import { getTimeSince } from '../../../utils';
import { MEMBER_TYPE } from '../../../pages/messages';

type MessageListProps = {
  memberType: MEMBER_TYPE;
  data: ChatListItemDto[];
  onMemberSelected: (userId: string) => void;
  selectedMemberId?: string;
  isLoading?: boolean;
};

export const MessageList = ({
  memberType,
  data,
  onMemberSelected,
  selectedMemberId,
  isLoading = false,
}: MessageListProps) => {
  return (
    <MessageListContainer className="messages-list">
      {isLoading ? (
        <div className="no-items">Loading ...</div>
      ) : (
        <>
          <div className="bottom-container inbox-list">
            {data.length ? (
              <>
                {data.map((el, index) => (
                  <div
                    className={`inbox-item ${
                      el.member._id === selectedMemberId && 'active'
                    }`}
                    key={index}
                    onClick={() => onMemberSelected(el.member._id)}
                  >
                    <div className="profile-image">
                      <UserIcon
                        firstName={el.member.firstName}
                        lastName={el.member.lastName}
                        entity={memberType}
                      />
                    </div>
                    <div className="inbox-container">
                      <div className="inbox-header">
                        <div className="inbox-name">
                          {el.member.firstName} {el.member.lastName}
                        </div>
                        <div className="inbox-time">
                          {el.lastMessageTimestamp
                            ? getTimeSince(el.lastMessageTimestamp)
                            : ''}
                        </div>
                      </div>
                      <div className="inbox-content">
                        <div className="last-message">
                          {el.lastMessage || 'click to open conversation'}
                        </div>
                        {Number(el?.unreadMessagesCount) > 0 && (
                          <div className="number-unread-messages">
                            {el.unreadMessagesCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="no-items">No results</div>
            )}
          </div>
        </>
      )}
    </MessageListContainer>
  );
};
