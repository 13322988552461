import { Navigate } from 'react-router-dom';
import { LoginPage } from '../pages/auth/login/login.page';
import { MessagesPage, MEMBER_TYPE } from '../pages/messages';
import { IRoute } from '../types/routes.type';
import { PagesUrls } from './page-urls';
import { isCRMApp } from '../environment/app.type';

export const MessagesRoutes: IRoute[] = [
  {
    titleKey: PagesUrls.MESSAGES.Index,
    path: PagesUrls.MESSAGES.Index,
    element: <Navigate to={PagesUrls.MESSAGES.Candidate} />,
    isVisibleOnSidebar: true,
  },
  {
    titleKey: PagesUrls.MESSAGES.Index,
    path: PagesUrls.MESSAGES.WildCard,
    element: <Navigate to={PagesUrls.MESSAGES.Candidate} />,
    isVisibleOnSidebar: true,
  },
  {
    titleKey: PagesUrls.MESSAGES.Index,
    path: PagesUrls.MESSAGES.Candidate,
    element: <MessagesPage type={MEMBER_TYPE.CANDIDATE} />,
  },
  {
    path: `${PagesUrls.MESSAGES.Candidate}/:memberId`,
    element: <MessagesPage type={MEMBER_TYPE.CANDIDATE} />,
  },
];

if (isCRMApp) {
  MessagesRoutes.push(
    {
      path: PagesUrls.MESSAGES.Client,
      element: <MessagesPage type={MEMBER_TYPE.CLIENT} />,
      isVisibleOnSidebar: true,
    },
    {
      path: `${PagesUrls.MESSAGES.Client}/:memberId`,
      element: <MessagesPage type={MEMBER_TYPE.CLIENT} />,
      isVisibleOnSidebar: true,
    },
  );
}
