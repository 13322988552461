import React, { useEffect, useState } from 'react';
import { Select, TitleCard, FieldText, Button, FieldFile } from '../../ui';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../../constants';
import { useForm } from 'react-hook-form';
import { User, UpdatePayrollDto } from '../../../backend/careo-api';
import { UpdatePayrollFormContainer } from './update-payroll-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import PayrollSchema from '../../../utils/validators/payrolls.validator';
import { handleFormsServerErrors } from '../../../utils/utils';

export const UpdatePayrollForm = ({
  onCancel,
  onSuccess,
  payrollIdToUpdate,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  payrollIdToUpdate: string;
}) => {
  const [usersList, setUsersList] = useState<User[]>([]);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    setFocus,
  } = useForm<UpdatePayrollDto>({
    resolver: yupResolver(PayrollSchema as any),
  });
  const formValues = watch();

  const getPayroll = async (id: string) => {
    try {
      const result = (await AxiosInstance.payrolls.payrollsControllerFindOne(
        id,
      )) as unknown as UpdatePayrollDto;
      reset({
        ...result,
        userId: (result as any).user?._id,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getPayroll(payrollIdToUpdate);
  }, [payrollIdToUpdate]);

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.payrolls
      .payrollsControllerUpdate(payrollIdToUpdate, {
        ...values,
        employmentType: '-',
        standardWorkingHoursPerDay: '-',
      })
      .then(() => {
        toast.success('Payroll provider updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <UpdatePayrollFormContainer>
      <Select
        placeholder="Select recruiter"
        required
        label="Recruiter"
        options={[
          ...usersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('userId')}
        error={errors.userId}
        control={control}
      />
      <TitleCard>Client Details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Payroll Provider Name"
        required
        register={register('payrollProviderName')}
        error={errors.payrollProviderName}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Vat number"
        type="number"
        register={register('vatNumber')}
        error={errors.vatNumber}
      />
      <TitleCard>Primary Contact</TitleCard>
      <div className="row">
        <div className="col-md-2">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
            control={control}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('leadContactFirstName')}
            error={errors.leadContactFirstName}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('leadContactLastName')}
            error={errors.leadContactLastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Job Title"
        required
        register={register('leadContactJobTitle')}
        error={errors.leadContactJobTitle}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Email Address"
        required
        register={register('leadContactEmail')}
        error={errors.leadContactEmail}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactMobilePhoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('leadContactMobilePhoneNumber')}
            error={errors.leadContactMobilePhoneNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactWorkPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            register={register('leadContactWorkPhoneNumber')}
            error={errors.leadContactWorkPhoneNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Website"
            required
            register={register('website')}
            error={errors.website}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Fax Number"
            register={register('leadContactFax')}
            error={errors.leadContactFax}
          />
        </div>
      </div>
      <>
        <TitleCard>Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Street Address"
          register={register('address.street', { required: true })}
          error={errors.address?.street}
        />
        <FieldText
          placeholder="Enter here ..."
          label="City"
          register={register('address.city', { required: true })}
          error={errors.address?.city}
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country', { required: true })}
              error={errors.address?.country}
              onChange={() => {
                setValue('address.region', '');
                setValue('address.county', '');
              }}
              control={control}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              required
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region', { required: 'true' })}
              error={errors.address?.region}
              disabled={!formValues.address?.country}
              control={control}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              required
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county', { required: 'true' })}
              error={errors.address?.county}
              disabled={!formValues.address?.region}
              control={control}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              required
              register={register('address.zip', { required: true })}
              error={errors.address?.zip}
            />
          </div>
        </div>
      </>
      <TitleCard>Bank details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Bank Name"
        register={register('bank.name')}
        error={errors.bank?.name}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Bank Account Name"
        register={register('bank.accountName')}
        error={errors.bank?.accountName}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Sort Code"
            register={register('bank.sortNumber')}
            error={errors.bank?.sortNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Account Number"
            register={register('bank.accountNumber')}
            error={errors.bank?.accountNumber}
          />
        </div>
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdatePayrollFormContainer>
  );
};
