/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface User {
  _id: string;
  email: string;
  password: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  role: 'admin' | 'recruiter' | 'officer' | 'recruitment_officer';
}

export interface HistoryLog {
  _id: string;
  /** @format date-time */
  actionDate: string;
  doneBy: User;
  entityName: string;
  entityId: string;
  actionType: string;
  data: object;
  highlights: string[];
}

export interface PaginationResultDto {
  data: any[][];
  page: number;
  pagesCount: number;
  documentsCount: number;
}

export enum TEntityNames {
  Users = 'users',
  PayrollExportSettings = 'payrollExportSettings',
  PayrollExports = 'payrollExports',
  Resumes = 'resumes',
  Invoices = 'invoices',
  Company = 'company',
  Department = 'department',
  Clients = 'clients',
  ClientHostedFiles = 'clientHostedFiles',
  CandidateHostedFiles = 'candidateHostedFiles',
  PayrollFiles = 'payrollFiles',
  EmailsCandidatesCRM = 'emailsCandidatesCRM',
  EmailsLogsClients = 'emailsLogsClients',
  WhatsappCandidatesCRMChat = 'whatsappCandidatesCRMChat',
  WhatsappClientsCRMChat = 'whatsappClientsCRMChat',
  TelegramLogs = 'telegramLogs',
  Applications = 'applications',
  Payrolls = 'payrolls',
  Candidates = 'candidates',
  Campaigns = 'campaigns',
  Jobs = 'jobs',
  TimesheetSettings = 'timesheetSettings',
  Timesheets = 'timesheets',
  Compliances = 'compliances',
  Availability = 'availability',
  HistoryLogs = 'historyLogs',
  Notifications = 'notifications',
  ComplianceFiles = 'complianceFiles',
  HostedFiles = 'hostedFiles',
}

export interface SignUpDto {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  role: 'admin' | 'recruiter' | 'officer' | 'recruitment_officer';
}

export interface SignInDto {
  email: string;
  password: string;
}

export interface ChatListItemDto {
  member: Candidate | Client;
  lastMessage: string;
  /** @format date-time */
  lastMessageTimestamp: string;
  unreadMessagesCount: number;
  isReply: boolean;
}

export interface ReadChatDto {
  memberId: string;
}

export interface HostedFile {
  _id: string;
  fileName: string;
  fileKey: string;
  candidate: object;
  client: object;
  isPublic: boolean;
  /** @format date-time */
  timestamp: string;
}

export interface Address {
  street?: string;
  city?: string;
  county?: string;
  region?: string;
  country?: string;
  zip?: string;
}

export interface Bank {
  name: string;
  accountName: string;
  accountNumber: string;
  sortNumber: string;
}

export interface Payroll {
  _id: string;
  payrollProviderName: string;
  vatNumber?: string;
  payrollLogo?: object;
  title: string;
  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactJobTitle: string;
  leadContactMobilePhoneNumber: string;
  leadContactWorkPhoneNumber: string;
  leadContactFax: string;
  website: string;
  account?: string;
  employmentType: string;
  standardWorkingHoursPerDay: string;
  document?: object;
  address: Address;
  bank: Bank;
  user: User;
}

export interface Candidate {
  _id: string;
  address: Address;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality: string;
  gender?: string;
  niNumber?: string;
  professionalRegistrationNumber?: string;
  professionalRegistrationAuthority?: string;
  dbsCertificateNumber?: string;
  hiredStatus?: string;
  department?: string;
  grade:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  designation: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  payAmount?: number;
  currentlyEmployed: boolean;
  whatsappId: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  aiMatched: boolean;
  notes?: string;
  recruiter?: User;
  officer?: User;
  payrollProvider: Payroll;
}

export interface WhatsappCandidatesCRMChat {
  _id: string;
  message: string;
  hostedFile?: HostedFile;
  timestamp: object;
  isRead?: boolean;
  isReply: boolean;
  candidate: Candidate;
}

export interface WhatsappClientsCRMChat {
  _id: string;
  message: string;
  hostedFile?: HostedFile;
  timestamp: object;
  isRead?: boolean;
  isReply: boolean;
  client: object;
}

export interface EmailsCandidatesCRM {
  _id: string;
  isReply: boolean;
  subject: string;
  body: string;
  hostedFiles: HostedFile[];
  /** @format date-time */
  timestamp: string;
  isRead: boolean;
  isDraft: boolean;
  candidate: Candidate;
}

export interface Department {
  titleContact: string;
  firstNameContact: string;
  lastNameContact: string;
  departmentContact: string;
  teamContact: string;
  jobTitleContact: string;
  emailContact: string;
  phoneNumberContact: string;
  workPhoneNumberContact: string;
  document?: string;
  titleAccount: string;
  firstNameAccount: string;
  lastNameAccount: string;
  jobTitleAccount: string;
  emailAccount: string;
  phoneNumberAccount: string;
  workPhoneNumberAccount: string;
  bank: Bank;
}

export interface Client {
  _id: string;
  clientName: string;
  clientSiteName: string;
  clientSite: string;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website: string;
  document?: string;
  address: Address;
  user: User;
  bank: Bank;
  departments: Department[];
  whatsappId: string;
}

export interface EmailsClientsCRM {
  _id: string;
  isReply: boolean;
  subject: string;
  body: string;
  hostedFiles: HostedFile[];
  /** @format date-time */
  timestamp: string;
  isRead: boolean;
  isDraft: boolean;
  user?: User;
  client: Client;
}

export interface SendEmailDto {
  recipient: string;
  recipientType: 'candidate' | 'client';
  subject: string;
  body: string;
}

export interface CreateDraftDto {
  recipient: string;
  recipientType: 'candidate' | 'client';
  subject: string;
  body: string;
}

export interface UpdateDraftDto {
  recipient?: string;
  recipientType?: 'candidate' | 'client';
  subject?: string;
  body?: string;
  hostedFiles: HostedFile[];
}

export interface ComplianceMetaData {
  _id: string;
  cvFile?: HostedFile;
  references?: HostedFile[];
  registrationCertificate?: HostedFile;
  registrationChecksFiles?: HostedFile;
  dbsCertificateFile?: HostedFile;
  dbsCheckFile?: HostedFile;
  onDbsUpdateService?: boolean;
}

export interface Compliance {
  _id: string;
  taskName:
    | 'Employment History'
    | 'Right to work'
    | 'Verified Identification'
    | 'DBS Check'
    | 'Qualifications'
    | 'Professional Registration'
    | 'Occupational Health';
  /** @format date-time */
  reminderDate?: string;
  /** @format date-time */
  expiryDate?: string;
  isRequired: boolean;
  isValid: boolean;
  status: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED';
  metadata?: ComplianceMetaData;
  candidate: object;
}

export interface UpdateComplianceDto {
  /** @format date-time */
  reminderDate?: string;
  /** @format date-time */
  expiryDate?: string;
  isRequired?: boolean;
  isValid?: boolean;
  metadata?: ComplianceMetaData;
}

export interface TaskAnalytics {
  /**
   * The count of tasks
   * @example 10
   */
  count: number;
  /**
   * The percentage of tasks
   * @example 50
   */
  percentage: number;
}

export interface GeneralAnalytics {
  /** In-progress tasks analytics */
  inProgressTasks: TaskAnalytics;
  /** Passed tasks analytics */
  passedTasks: TaskAnalytics;
  /** Failed tasks analytics */
  failedTasks: TaskAnalytics;
  /** Expired tasks analytics */
  expiredTasks: TaskAnalytics;
}

export interface CreateNotificationDto {
  type:
    | 'CANDIDATE_MATCH'
    | 'COMPLIANCE_REQUIRED'
    | 'COMPLIANCE_WARNING_EXPIRATION'
    | 'COMPLIANCE_EXPIRED'
    | 'COMPLIANCE_NEW_PARSED_DOCUMENT'
    | 'AI_VACANCY_CREATED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_FAILED'
    | 'COMPLIANCE_PRO_REG_VALIDATION_SUCCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_FAILED'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS'
    | 'CANDIDATE_REJECT_PLACEMENT'
    | 'CANDIDATE_APPROVED_PLACEMENT'
    | 'CLIENT_REJECT_PLACEMENT'
    | 'CLIENT_APPROVED_PLACEMENT';
  data: object;
}

export interface Notification {
  _id: string;
  type:
    | 'CANDIDATE_MATCH'
    | 'COMPLIANCE_REQUIRED'
    | 'COMPLIANCE_WARNING_EXPIRATION'
    | 'COMPLIANCE_EXPIRED'
    | 'COMPLIANCE_NEW_PARSED_DOCUMENT'
    | 'AI_VACANCY_CREATED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_FAILED'
    | 'COMPLIANCE_PRO_REG_VALIDATION_SUCCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_FAILED'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS'
    | 'CANDIDATE_REJECT_PLACEMENT'
    | 'CANDIDATE_APPROVED_PLACEMENT'
    | 'CLIENT_REJECT_PLACEMENT'
    | 'CLIENT_APPROVED_PLACEMENT';
  data: object;
  /** @format date-time */
  createdDate: string;
  /** @format date-time */
  viewedDate?: string;
  /** @format date-time */
  deletedDate?: string;
}

export interface HideNotificationDto {
  ids: string[];
}

export interface UpdateNotificationDto {
  type?:
    | 'CANDIDATE_MATCH'
    | 'COMPLIANCE_REQUIRED'
    | 'COMPLIANCE_WARNING_EXPIRATION'
    | 'COMPLIANCE_EXPIRED'
    | 'COMPLIANCE_NEW_PARSED_DOCUMENT'
    | 'AI_VACANCY_CREATED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED'
    | 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_PRO_REG_VALIDATION_FAILED'
    | 'COMPLIANCE_PRO_REG_VALIDATION_SUCCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_FAILED'
    | 'COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS'
    | 'CANDIDATE_REJECT_PLACEMENT'
    | 'CANDIDATE_APPROVED_PLACEMENT'
    | 'CLIENT_REJECT_PLACEMENT'
    | 'CLIENT_APPROVED_PLACEMENT';
  data?: object;
}

export interface CreateJobDto {
  grade:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  level: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  specialty: string;
  jobDescription: string;
  status: 'hired' | 'pending' | 'active' | 'closed';
  /** @format date-time */
  vacantFrom: string;
  /** @format date-time */
  vacantTo: string;
  userId?: string;
  clientId: string;
  payOption: 'time' | 'fixed';
  payAmount: number;
  hoursPerWeek: number;
  vat: string;
}

export interface Job {
  _id: string;
  grade:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  level: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  specialty: string;
  jobDescription: string;
  status: 'hired' | 'pending' | 'active' | 'closed';
  /** @format date-time */
  vacantFrom: string;
  /** @format date-time */
  vacantTo: string;
  user?: User;
  client: Client;
  payOption: 'time' | 'fixed';
  payAmount: number;
  hoursPerWeek: number;
  vat: string;
}

export interface UpdateJobDto {
  grade?:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  level?: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  specialty?: string;
  jobDescription?: string;
  status?: 'hired' | 'pending' | 'active' | 'closed';
  /** @format date-time */
  vacantFrom?: string;
  /** @format date-time */
  vacantTo?: string;
  userId?: string;
  clientId?: string;
  payOption?: 'time' | 'fixed';
  payAmount?: number;
  hoursPerWeek?: number;
  vat?: string;
}

export interface CreateUserDto {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: 'admin' | 'recruiter' | 'officer' | 'recruitment_officer';
}

export interface UpdateUserDto {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  role?: 'admin' | 'recruiter' | 'officer' | 'recruitment_officer';
}

export interface CreateAddressDto {
  street: string;
  city: string;
  county: string;
  region: string;
  country: string;
  zip: string;
}

export interface CreateCandidateDto {
  address: CreateAddressDto;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate: string;
  nationality: string;
  gender?: string;
  niNumber: string;
  hiredStatus?: string;
  department: string;
  grade:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  designation: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  payAmount: number;
  currentlyEmployed: boolean;
  whatsappId: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  payrollProviderId: string;
  recruiterId?: string;
  officerId?: string;
}

export interface UpdateCandidateDto {
  address?: CreateAddressDto;
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality?: string;
  gender?: string;
  niNumber?: string;
  hiredStatus?: string;
  department?: string;
  grade?:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  designation?: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  payAmount?: number;
  currentlyEmployed?: boolean;
  whatsappId?: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  payrollProviderId?: string;
  recruiterId?: string;
  officerId?: string;
  professionalRegistrationNumber: string;
  professionalRegistrationAuthority: string;
  dbsCertificateNumber: string;
}

export interface ExtendedCandidate {
  _id: string;
  address: Address;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  /** @format date-time */
  birthDate?: string;
  nationality: string;
  gender?: string;
  niNumber?: string;
  professionalRegistrationNumber?: string;
  professionalRegistrationAuthority?: string;
  dbsCertificateNumber?: string;
  hiredStatus?: string;
  department?: string;
  grade:
    | 'Band 5'
    | 'Band 6'
    | 'Band 7'
    | 'Band 8a'
    | 'Band 8b'
    | 'Band 8c'
    | 'Band 8d'
    | 'Band 9'
    | 'FY1'
    | 'FY2'
    | 'ST1'
    | 'ST2'
    | 'ST3'
    | 'ST4'
    | 'ST5'
    | 'ST6'
    | 'ST7'
    | 'ST8'
    | 'Associate Specialist'
    | 'Staff Grade'
    | 'Senior House Officer'
    | 'Specialty Doctor'
    | 'Consultant';
  designation: 'Nurse' | 'Junior Doctor' | 'Middle Grade' | 'Consultant';
  payAmount?: number;
  currentlyEmployed: boolean;
  whatsappId: string;
  drivingLicence?: boolean;
  ownCar?: boolean;
  aiMatched: boolean;
  notes?: string;
  recruiter?: User;
  officer?: User;
  payrollProvider: Payroll;
  complianceStatus: 'IN_PROGRESS' | 'PASSED' | 'FAILED' | 'EXPIRED';
  completionPercentage: number;
}

export interface UpdateNoteCandidateDto {
  notes: string;
}

export interface CreateApplicationDurationDto {
  availableFrom: string;
  availableTo: string;
}

export interface CreateApplicationDto {
  status?: 'referred' | 'ai_matched' | 'rejected' | 'shortlist' | 'placement';
  appliedOn?: string;
  durations: CreateApplicationDurationDto[];
  jobId: string;
  candidateId: string;
}

export interface Application {
  _id: string;
  status: 'referred' | 'ai_matched' | 'rejected' | 'shortlist' | 'placement';
  appliedOn?: string;
  availableFrom: string;
  availableTo: string;
  job: Job;
  candidate: Candidate;
  fee?: number;
  onCallFee?: number;
  onCallRate?: number;
  vat?: number;
  approvalStatus?: 'NOT_ACTIVE' | 'PENDING' | 'CANDIDATE_APPROVED' | 'CANDIDATE_REJECTED' | 'APPROVED' | 'REJECTED';
  candidateToken?: string;
  clientToken?: string;
}

export interface UpdateApplicationStatusDto {
  status: 'referred' | 'ai_matched' | 'rejected' | 'shortlist' | 'placement';
}

export interface ValidatePlacementDto {
  fee: number;
  vat: number;
  onCallFee: number;
  onCallRate: number;
}

export interface AddAvailabilityDto {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to?: string;
  availabilityTime: 'fullDay' | 'morning' | 'afternoon';
  candidateId: string;
}

export interface Availability {
  _id: string;
  /** @format date-time */
  date: string;
  availabilityTime: 'fullDay' | 'morning' | 'afternoon';
  status: 'AVAILABLE' | 'PLACED';
  candidate: Candidate;
}

export interface DeleteAvailabilityDto {
  /** @format date-time */
  from: string;
  /** @format date-time */
  to?: string;
  candidateId: string;
}

export interface CreateBankDto {
  name: string;
  accountName: string;
  accountNumber: string;
  sortNumber: string;
}

export interface CreatePayrollDto {
  payrollProviderName: string;
  vatNumber?: string;
  payrollLogo?: object;
  title: string;
  leadContactFirstName: string;
  leadContactLastName: string;
  leadContactEmail: string;
  leadContactJobTitle: string;
  leadContactMobilePhoneNumber: string;
  leadContactWorkPhoneNumber: string;
  leadContactFax: string;
  website: string;
  account?: string;
  employmentType: string;
  standardWorkingHoursPerDay: string;
  document?: object;
  address: CreateAddressDto;
  bank: CreateBankDto;
  userId: string;
}

export interface UpdatePayrollDto {
  payrollProviderName?: string;
  vatNumber?: string;
  payrollLogo?: object;
  title?: string;
  leadContactFirstName?: string;
  leadContactLastName?: string;
  leadContactEmail?: string;
  leadContactJobTitle?: string;
  leadContactMobilePhoneNumber?: string;
  leadContactWorkPhoneNumber?: string;
  leadContactFax?: string;
  website?: string;
  account?: string;
  employmentType?: string;
  standardWorkingHoursPerDay?: string;
  document?: object;
  address?: CreateAddressDto;
  bank?: CreateBankDto;
  userId?: string;
}

export interface CreateCandidatesListDto {
  name: string;
  candidates: string[];
  sharedUsers: string[];
}

export interface CandidatesList {
  _id: string;
  name: string;
  user: User;
  sharedUsers: User[];
  candidates: Candidate[];
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface UpdateCandidatesListDto {
  name?: string;
  candidates?: string[];
  sharedUsers?: string[];
}

export interface Timing {
  /** @format date-time */
  date: string;
  startTime?: string;
  endTime?: string;
  callHours?: number;
  breakTime?: number;
}

export interface Timesheet {
  _id: string;
  placement: Application;
  timings: Timing[];
  signature?: string;
  status: 'Generated' | 'Pending' | 'Approved' | 'Rejected';
  approval: 'Pending' | 'Approved' | 'Rejected';
  /** @format date-time */
  generatedDate: string;
  clientToken?: string;
  candidateToken?: string;
}

export interface UpdateTimesheetSettingsDTO {
  frequency: 'daily' | 'weekly' | 'monthly';
  dayOfTheWeek?: number;
  time: string;
}

export interface AddTimingsDto {
  /** @format date-time */
  date: string;
  startTime: string;
  endTime: string;
  callHours: number;
  breakTime: number;
}

export interface AddTimingsArrayDto {
  timings: AddTimingsDto[];
}

export interface ApproveDto {
  signature: string;
}

export interface CreateInvoiceDto {
  placementId: string;
  timesheetId: string;
}

export interface Invoice {
  _id: string;
  placement: Application;
  timesheet: Timesheet;
  /** @format date-time */
  createdDate: string;
  referenceNumber: number;
}

export interface CreateClientDto {
  clientName: string;
  clientSiteName: string;
  clientSite?: string;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website: string;
  document?: string;
  address: CreateAddressDto;
  userId: string;
  bank: CreateBankDto;
  whatsappId: string;
}

export interface UpdateClientDto {
  clientName?: string;
  clientSiteName?: string;
  clientSite?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  email?: string;
  phoneNumber?: string;
  workPhoneNumber?: string;
  faxNumber?: string;
  website?: string;
  document?: string;
  address?: CreateAddressDto;
  userId?: string;
  bank?: CreateBankDto;
  whatsappId?: string;
}

export interface CreateDepartmentClientDto {
  titleContact: string;
  firstNameContact: string;
  lastNameContact: string;
  departmentContact: string;
  teamContact: string;
  jobTitleContact: string;
  emailContact: string;
  phoneNumberContact: string;
  workPhoneNumberContact: string;
  document?: string;
  titleAccount: string;
  firstNameAccount: string;
  lastNameAccount: string;
  jobTitleAccount: string;
  emailAccount: string;
  phoneNumberAccount: string;
  workPhoneNumberAccount: string;
  bank: CreateBankDto;
}

export interface SendMessageDto {
  /** Candidate/Client id */
  recipientId: string;
  recipientType: 'candidate' | 'client';
  message: string;
  /** @format binary */
  file: File;
}

export interface ReceiveMessageDto {
  SmsMessageSid: string;
  NumMedia: string;
  ProfileName: string;
  SmsSid: string;
  WaId: string;
  SmsStatus: string;
  Body: string;
  To: string;
  NumSegments: string;
  ReferralNumMedia: string;
  MessageSid: string;
  AccountSid: object;
  From: string;
  ApiVersion: string;
  MediaContentType0: string;
  MediaUrl0: string;
}

export interface CreateResumeDto {
  document: object;
  coverLetter?: object;
  photo?: object;
  dateCaptured?: string;
  candidateId: string;
}

export interface Resume {
  document: object;
  coverLetter?: object;
  photo?: object;
  dateCaptured?: string;
  candidate: object;
}

export interface CreateCampaignDto {
  name: string;
  channel: 'Whatsapp' | 'Email';
  frequency?: 'daily' | 'weekly' | 'monthly';
  /** @format date-time */
  startDate: string;
  runningTime: string;
  campaignTemplateId: string;
}

export interface CampaignTemplate {
  _id: string;
  name: string;
  message: string;
}

export interface Campaign {
  _id: string;
  name: string;
  channel: 'Whatsapp' | 'Email';
  frequency?: 'daily' | 'weekly' | 'monthly';
  /** @format date-time */
  startDate: string;
  runningTime: string;
  isActive: boolean;
  user: User;
  template: CampaignTemplate;
}

export interface UpdateCampaignDto {
  name?: string;
  channel?: 'Whatsapp' | 'Email';
  frequency?: 'daily' | 'weekly' | 'monthly';
  /** @format date-time */
  startDate?: string;
  runningTime?: string;
  campaignTemplateId?: string;
  isActive?: boolean;
}

export interface CreateCampaignTemplateDto {
  name: string;
  message: string;
}

export interface UpdateCampaignTemplateDto {
  name?: string;
  message?: string;
}

export interface Company {
  _id: string;
  name: string;
  website: string;
  vat: string;
  address: Address;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  bank: Bank;
}

export interface CreateCompanyDto {
  name: string;
  vat: string;
  title: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  phoneNumber: string;
  website: string;
  address: CreateAddressDto;
  bank: CreateBankDto;
}

export interface PayrollExport {
  _id: string;
  timesheets: Timesheet[];
  /** @format date-time */
  createdDate: string;
  referenceNumber: number;
  status: string;
  payroll: Payroll;
}

export interface PayrollExportSettings {
  _id: string;
  frequency: 'daily' | 'weekly' | 'monthly';
  dayOfTheWeek: number;
  time: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Careo API
 * @version 1.0
 * @contact
 *
 * API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  historyLogs = {
    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerGetHistoryLog
     * @request GET:/historyLogs/{id}
     */
    historyLogsControllerGetHistoryLog: (id: string, params: RequestParams = {}) =>
      this.request<any, HistoryLog>({
        path: `/historyLogs/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerDeleteHistoryLog
     * @request DELETE:/historyLogs/{id}
     */
    historyLogsControllerDeleteHistoryLog: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/historyLogs/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags HistoryLogs
     * @name HistoryLogsControllerGetAllHistoryLogs
     * @request GET:/historyLogs
     */
    historyLogsControllerGetAllHistoryLogs: (
      query: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        doneBy?: string;
        entityName: TEntityNames;
        entityId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | HistoryLog[], any>({
        path: `/historyLogs`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignUp
     * @request POST:/auth/signup
     */
    authControllerSignUp: (data: SignUpDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignIn
     * @request POST:/auth/signin
     */
    authControllerSignIn: (data: SignInDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyToken
     * @request GET:/auth/verify-token
     * @secure
     */
    authControllerVerifyToken: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/verify-token`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  socialPlatforms = {
    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerFetchMemberChats
     * @request GET:/social-platforms/{platform}/{portal}/{memberType}/chats/{memberId}
     * @secure
     */
    socialPlatformsControllerFetchMemberChats: (
      platform: string,
      portal: string,
      memberType: string,
      memberId: string,
      query: {
        box: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/chats/${memberId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerFetchChatList
     * @request GET:/social-platforms/{platform}/{portal}/{memberType}/chat-list
     * @secure
     */
    socialPlatformsControllerFetchChatList: (
      platform: string,
      portal: string,
      memberType: string,
      query: {
        box: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, ChatListItemDto[]>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/chat-list`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerMarkChatAsRead
     * @request POST:/social-platforms/{platform}/{portal}/{memberType}/read-chat
     * @secure
     */
    socialPlatformsControllerMarkChatAsRead: (
      platform: string,
      portal: string,
      memberType: string,
      data: ReadChatDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/social-platforms/${platform}/${portal}/${memberType}/read-chat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerSample1
     * @request POST:/social-platforms/sample1
     * @secure
     */
    socialPlatformsControllerSample1: (params: RequestParams = {}) =>
      this.request<any, WhatsappCandidatesCRMChat>({
        path: `/social-platforms/sample1`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerSample2
     * @request POST:/social-platforms/sample2
     * @secure
     */
    socialPlatformsControllerSample2: (params: RequestParams = {}) =>
      this.request<any, WhatsappClientsCRMChat>({
        path: `/social-platforms/sample2`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerSample3
     * @request POST:/social-platforms/sample3
     * @secure
     */
    socialPlatformsControllerSample3: (params: RequestParams = {}) =>
      this.request<any, EmailsCandidatesCRM>({
        path: `/social-platforms/sample3`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Social-Platforms
     * @name SocialPlatformsControllerSample4
     * @request POST:/social-platforms/sample4
     * @secure
     */
    socialPlatformsControllerSample4: (params: RequestParams = {}) =>
      this.request<any, EmailsClientsCRM>({
        path: `/social-platforms/sample4`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  emails = {
    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerSendEmail
     * @request POST:/emails/{portal}/send-email
     * @secure
     */
    emailsControllerSendEmail: (portal: string, data: SendEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails/${portal}/send-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails
     * @name EmailsControllerDeleteEmail
     * @request DELETE:/emails/{portal}/{emailId}
     * @secure
     */
    emailsControllerDeleteEmail: (
      portal: string,
      emailId: string,
      query: {
        memberType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/emails/${portal}/${emailId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),
  };
  emailsDrafts = {
    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerCreate
     * @request POST:/emails-drafts/{portal}/create
     * @secure
     */
    emailDraftsControllerCreate: (portal: string, data: CreateDraftDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/emails-drafts/${portal}/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerUpdateDraft
     * @request PATCH:/emails-drafts/{portal}/{draftId}
     * @secure
     */
    emailDraftsControllerUpdateDraft: (
      portal: string,
      draftId: string,
      data: UpdateDraftDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/emails-drafts/${portal}/${draftId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerDeleteEmail
     * @request DELETE:/emails-drafts/{portal}/{draftId}
     * @secure
     */
    emailDraftsControllerDeleteEmail: (
      portal: string,
      draftId: string,
      query: {
        memberType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/emails-drafts/${portal}/${draftId}`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags emails-drafts
     * @name EmailDraftsControllerSendDraftEmail
     * @request POST:/emails-drafts/{portal}/{draftId}/send
     * @secure
     */
    emailDraftsControllerSendDraftEmail: (
      portal: string,
      draftId: string,
      query: {
        memberType: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/emails-drafts/${portal}/${draftId}/send`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),
  };
  files = {
    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadCandidateFile
     * @request POST:/files/upload/candidates/{candidateId}
     * @secure
     */
    filesControllerUploadCandidateFile: (
      candidateId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload/candidates/${candidateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerUploadCandidateFiles
     * @request POST:/files/upload-multiple/candidates/{candidateId}
     * @secure
     */
    filesControllerUploadCandidateFiles: (
      candidateId: string,
      data: {
        /** @format binary */
        file?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/files/upload-multiple/candidates/${candidateId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindCandidateDocs
     * @request GET:/files/candidates/{candidateId}
     * @secure
     */
    filesControllerFindCandidateDocs: (candidateId: string, params: RequestParams = {}) =>
      this.request<any, HostedFile>({
        path: `/files/candidates/${candidateId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerFindClientDocs
     * @request GET:/files/clients/{clientId}
     * @secure
     */
    filesControllerFindClientDocs: (clientId: string, params: RequestParams = {}) =>
      this.request<any, HostedFile>({
        path: `/files/clients/${clientId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDeleteFile
     * @request DELETE:/files/{fileId}
     * @secure
     */
    filesControllerDeleteFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/${fileId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerRenameCandidateFile
     * @request PATCH:/files/rename/{fileIdOrKey}/{newFileName}
     * @secure
     */
    filesControllerRenameCandidateFile: (fileIdOrKey: string, newFileName: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/rename/${fileIdOrKey}/${newFileName}`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesControllerDownloadFile
     * @request GET:/files/download/{fileId}
     * @secure
     */
    filesControllerDownloadFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/files/download/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  publicFiles = {
    /**
     * No description
     *
     * @tags Public Files
     * @name PublicFilesControllerDownloadFile
     * @request GET:/public-files/download/{fileId}
     */
    publicFilesControllerDownloadFile: (fileId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/public-files/download/${fileId}`,
        method: 'GET',
        ...params,
      }),
  };
  compliances = {
    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerGetCompliance
     * @request GET:/compliances/{id}
     * @secure
     */
    compliancesControllerGetCompliance: (id: string, params: RequestParams = {}) =>
      this.request<any, Compliance>({
        path: `/compliances/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerUpdate
     * @request PUT:/compliances/{id}
     * @secure
     */
    compliancesControllerUpdate: (
      id: string,
      query: {
        validate: boolean;
      },
      data: UpdateComplianceDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/compliances/${id}`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerDeleteCompliance
     * @request DELETE:/compliances/{id}
     * @secure
     */
    compliancesControllerDeleteCompliance: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/compliances/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerGetAllCompliances
     * @request GET:/compliances
     * @secure
     */
    compliancesControllerGetAllCompliances: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        candidateId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Compliance[], any>({
        path: `/compliances`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Compliances
     * @name CompliancesControllerGetGeneralAnalytics
     * @request GET:/compliances/data/analytics
     * @secure
     */
    compliancesControllerGetGeneralAnalytics: (params: RequestParams = {}) =>
      this.request<any, GeneralAnalytics>({
        path: `/compliances/data/analytics`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  notifications = {
    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerCreate
     * @request POST:/notifications
     * @secure
     */
    notificationsControllerCreate: (data: CreateNotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerFindAll
     * @request GET:/notifications
     * @secure
     */
    notificationsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Notification[], any>({
        path: `/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerFindOne
     * @request GET:/notifications/{id}
     * @secure
     */
    notificationsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Notification>({
        path: `/notifications/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerUpdate
     * @request PUT:/notifications/{id}
     * @secure
     */
    notificationsControllerUpdate: (id: string, data: UpdateNotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerDelete
     * @request DELETE:/notifications/{id}
     * @secure
     */
    notificationsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerHideNotifications
     * @request PUT:/notifications/hide-notification
     * @secure
     */
    notificationsControllerHideNotifications: (data: HideNotificationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/hide-notification`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationsControllerViewNotification
     * @request PUT:/notifications/{id}/view
     * @secure
     */
    notificationsControllerViewNotification: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/${id}/view`,
        method: 'PUT',
        secure: true,
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerCreateJob
     * @request POST:/jobs
     * @secure
     */
    jobsControllerCreateJob: (data: CreateJobDto, params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerGetAllJobs
     * @request GET:/jobs
     * @secure
     */
    jobsControllerGetAllJobs: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Job[], any>({
        path: `/jobs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUploadJobs
     * @request POST:/jobs/upload-csv
     * @secure
     */
    jobsControllerUploadJobs: (params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerGetJob
     * @request GET:/jobs/{id}
     * @secure
     */
    jobsControllerGetJob: (id: string, params: RequestParams = {}) =>
      this.request<any, Job>({
        path: `/jobs/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUpdateJobStatus
     * @request PUT:/jobs/{id}
     * @secure
     */
    jobsControllerUpdateJobStatus: (id: string, data: UpdateJobDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerDeleteJob
     * @request DELETE:/jobs/{id}
     * @secure
     */
    jobsControllerDeleteJob: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Jobs
     * @name JobsControllerUpdate
     * @request PUT:/jobs/{id}/update
     * @secure
     */
    jobsControllerUpdate: (id: string, data: UpdateJobDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/jobs/${id}/update`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreateUser
     * @request POST:/users
     * @secure
     */
    usersControllerCreateUser: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<any, User>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetAllUsers
     * @request GET:/users
     * @secure
     */
    usersControllerGetAllUsers: (
      query?: {
        userRole?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, User[]>({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUploadUsers
     * @request POST:/users/upload-csv
     * @secure
     */
    usersControllerUploadUsers: (params: RequestParams = {}) =>
      this.request<any, User>({
        path: `/users/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerGetUser
     * @request GET:/users/{id}
     * @secure
     */
    usersControllerGetUser: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerUpdate
     * @request PUT:/users/{id}
     * @secure
     */
    usersControllerUpdate: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerDeleteUser
     * @request DELETE:/users/{id}
     * @secure
     */
    usersControllerDeleteUser: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  candidates = {
    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerCreateCandidate
     * @request POST:/candidates
     * @secure
     */
    candidatesControllerCreateCandidate: (data: CreateCandidateDto, params: RequestParams = {}) =>
      this.request<any, Candidate>({
        path: `/candidates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerGetAllCandidates
     * @request GET:/candidates
     * @secure
     */
    candidatesControllerGetAllCandidates: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        analytics?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Candidate[], any>({
        path: `/candidates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerUploadCandidates
     * @request POST:/candidates/upload-csv
     * @secure
     */
    candidatesControllerUploadCandidates: (params: RequestParams = {}) =>
      this.request<any, Candidate>({
        path: `/candidates/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerGetCandidate
     * @request GET:/candidates/{id}
     * @secure
     */
    candidatesControllerGetCandidate: (
      id: string,
      query?: {
        /** Include analytics data */
        analytics?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, Candidate>({
        path: `/candidates/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerUpdate
     * @request PUT:/candidates/{id}
     * @secure
     */
    candidatesControllerUpdate: (id: string, data: UpdateCandidateDto, params: RequestParams = {}) =>
      this.request<any, ExtendedCandidate>({
        path: `/candidates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerDeleteCandidate
     * @request DELETE:/candidates/{id}
     * @secure
     */
    candidatesControllerDeleteCandidate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/candidates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates
     * @name CandidatesControllerUpdateNoteCandidate
     * @request PATCH:/candidates/{id}/note
     * @secure
     */
    candidatesControllerUpdateNoteCandidate: (id: string, data: UpdateNoteCandidateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/candidates/${id}/note`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  applications = {
    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerCreate
     * @request POST:/applications
     * @secure
     */
    applicationsControllerCreate: (data: CreateApplicationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerGetAllApplications
     * @request GET:/applications
     * @secure
     */
    applicationsControllerGetAllApplications: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        jobId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Application[], any>({
        path: `/applications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerGetApplication
     * @request GET:/applications/{id}
     * @secure
     */
    applicationsControllerGetApplication: (id: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/applications/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerUpdateApplicationStatus
     * @request PUT:/applications/{id}
     * @secure
     */
    applicationsControllerUpdateApplicationStatus: (
      id: string,
      data: UpdateApplicationStatusDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/applications/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerDeleteApplication
     * @request DELETE:/applications/{id}
     * @secure
     */
    applicationsControllerDeleteApplication: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerGetAllPlacements
     * @request GET:/applications/placements/list
     * @secure
     */
    applicationsControllerGetAllPlacements: (params: RequestParams = {}) =>
      this.request<any, Application[]>({
        path: `/applications/placements/list`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerUpdatePlacement
     * @request PUT:/applications/{id}/update-placement
     * @secure
     */
    applicationsControllerUpdatePlacement: (
      id: string,
      data: CreateApplicationDurationDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/applications/${id}/update-placement`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerValidatePlacement
     * @request PUT:/applications/{id}/validate
     * @secure
     */
    applicationsControllerValidatePlacement: (id: string, data: ValidatePlacementDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/${id}/validate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name ApplicationsControllerUpdateDuration
     * @request PATCH:/applications/update/durations
     * @secure
     */
    applicationsControllerUpdateDuration: (data: CreateApplicationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/applications/update/durations`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  placementMember = {
    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerFindOneByCandidateToken
     * @request GET:/placement-member/{token}/candidate
     */
    placementMemberControllerFindOneByCandidateToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/placement-member/${token}/candidate`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerFindOneByClientToken
     * @request GET:/placement-member/{token}/client
     */
    placementMemberControllerFindOneByClientToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Application>({
        path: `/placement-member/${token}/client`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerApproveByCandidateToken
     * @request PUT:/placement-member/{candidateToken}/candidate/approve
     */
    placementMemberControllerApproveByCandidateToken: (candidateToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${candidateToken}/candidate/approve`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerRejectByCandidateToken
     * @request PUT:/placement-member/{candidateToken}/candidate/reject
     */
    placementMemberControllerRejectByCandidateToken: (candidateToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${candidateToken}/candidate/reject`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerApproveByClientToken
     * @request PUT:/placement-member/{clientToken}/client/approve
     */
    placementMemberControllerApproveByClientToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${clientToken}/client/approve`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PlacementMember
     * @name PlacementMemberControllerRejectByClientToken
     * @request PUT:/placement-member/{clientToken}/client/reject
     */
    placementMemberControllerRejectByClientToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/placement-member/${clientToken}/client/reject`,
        method: 'PUT',
        ...params,
      }),
  };
  availability = {
    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerAddAvailability
     * @request POST:/availability
     * @secure
     */
    availabilityControllerAddAvailability: (data: AddAvailabilityDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/availability`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerFindAllByCandidateId
     * @request GET:/availability/candidates/{candidateId}
     * @secure
     */
    availabilityControllerFindAllByCandidateId: (candidateId: string, params: RequestParams = {}) =>
      this.request<any, Availability[]>({
        path: `/availability/candidates/${candidateId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerGetAvailability
     * @request GET:/availability/{id}
     * @secure
     */
    availabilityControllerGetAvailability: (id: string, params: RequestParams = {}) =>
      this.request<any, Availability>({
        path: `/availability/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerDeleteById
     * @request DELETE:/availability/{id}
     * @secure
     */
    availabilityControllerDeleteById: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/availability/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Availability
     * @name AvailabilityControllerDeleteAvailabilityByRangeDate
     * @request DELETE:/availability/by-range/date
     * @secure
     */
    availabilityControllerDeleteAvailabilityByRangeDate: (data: DeleteAvailabilityDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/availability/by-range/date`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  payrolls = {
    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerCreate
     * @request POST:/payrolls
     * @secure
     */
    payrollsControllerCreate: (data: CreatePayrollDto, params: RequestParams = {}) =>
      this.request<any, Payroll>({
        path: `/payrolls`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerFindAll
     * @request GET:/payrolls
     * @secure
     */
    payrollsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Payroll[], any>({
        path: `/payrolls`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerUploadPayrolls
     * @request POST:/payrolls/upload-csv
     * @secure
     */
    payrollsControllerUploadPayrolls: (params: RequestParams = {}) =>
      this.request<any, Payroll>({
        path: `/payrolls/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerFindOne
     * @request GET:/payrolls/{id}
     * @secure
     */
    payrollsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Payroll>({
        path: `/payrolls/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerUpdate
     * @request PUT:/payrolls/{id}
     * @secure
     */
    payrollsControllerUpdate: (id: string, data: UpdatePayrollDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payrolls/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payrolls
     * @name PayrollsControllerDelete
     * @request DELETE:/payrolls/{id}
     * @secure
     */
    payrollsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payrolls/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  candidatesLists = {
    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerCreate
     * @request POST:/candidates-lists
     * @secure
     */
    candidatesListsControllerCreate: (data: CreateCandidatesListDto, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerFindAll
     * @request GET:/candidates-lists
     * @secure
     */
    candidatesListsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | CandidatesList[], any>({
        path: `/candidates-lists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerFindOne
     * @request GET:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerFindOne: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerUpdate
     * @request PATCH:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerUpdate: (listId: string, data: UpdateCandidatesListDto, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerRemove
     * @request DELETE:/candidates-lists/{listId}
     * @secure
     */
    candidatesListsControllerRemove: (listId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/candidates-lists/${listId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerAddCandidate
     * @request POST:/candidates-lists/{listId}/add-candidate
     * @secure
     */
    candidatesListsControllerAddCandidate: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}/add-candidate`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Candidates Lists (sharing lists)
     * @name CandidatesListsControllerDisjoinList
     * @request POST:/candidates-lists/{listId}/disjoin
     * @secure
     */
    candidatesListsControllerDisjoinList: (listId: string, params: RequestParams = {}) =>
      this.request<any, CandidatesList>({
        path: `/candidates-lists/${listId}/disjoin`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  timesheets = {
    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerFindOne
     * @request GET:/timesheets/{id}
     * @secure
     */
    timesheetsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Timesheet>({
        path: `/timesheets/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerDelete
     * @request DELETE:/timesheets/{id}
     * @secure
     */
    timesheetsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheets/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerGetTimesheetSettings
     * @request GET:/timesheets/settings/timesheet
     * @secure
     */
    timesheetsControllerGetTimesheetSettings: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheets/settings/timesheet`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerFindAll
     * @request GET:/timesheets
     * @secure
     */
    timesheetsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        placementId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Timesheet[], any>({
        path: `/timesheets`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Timesheets
     * @name TimesheetsControllerUpdateTimesheetSettings
     * @request PATCH:/timesheets/settings
     * @secure
     */
    timesheetsControllerUpdateTimesheetSettings: (data: UpdateTimesheetSettingsDTO, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheets/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  timesheetMember = {
    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerFindOneByToken
     * @request GET:/timesheet-member/{token}
     */
    timesheetMemberControllerFindOneByToken: (token: string, params: RequestParams = {}) =>
      this.request<any, Timesheet>({
        path: `/timesheet-member/${token}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerAddTimingsByCandidateToken
     * @request PUT:/timesheet-member/{candidateToken}/add-timings
     */
    timesheetMemberControllerAddTimingsByCandidateToken: (
      candidateToken: string,
      data: AddTimingsArrayDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/timesheet-member/${candidateToken}/add-timings`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerApproveByClientToken
     * @request PUT:/timesheet-member/{clientToken}/approve
     */
    timesheetMemberControllerApproveByClientToken: (
      clientToken: string,
      data: ApproveDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/timesheet-member/${clientToken}/approve`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags TimesheetMember
     * @name TimesheetMemberControllerRejectByClientToken
     * @request PUT:/timesheet-member/{clientToken}/reject
     */
    timesheetMemberControllerRejectByClientToken: (clientToken: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/timesheet-member/${clientToken}/reject`,
        method: 'PUT',
        ...params,
      }),
  };
  invoices = {
    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerCreate
     * @request POST:/invoices
     * @secure
     */
    invoicesControllerCreate: (data: CreateInvoiceDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/invoices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerFindAll
     * @request GET:/invoices
     * @secure
     */
    invoicesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        placementId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Invoice[], any>({
        path: `/invoices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicesControllerFindOne
     * @request GET:/invoices/{id}
     * @secure
     */
    invoicesControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Invoice>({
        path: `/invoices/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  clients = {
    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerCreateClient
     * @request POST:/clients
     * @secure
     */
    clientsControllerCreateClient: (data: CreateClientDto, params: RequestParams = {}) =>
      this.request<any, Client>({
        path: `/clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerGetAllClients
     * @request GET:/clients
     * @secure
     */
    clientsControllerGetAllClients: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Client[], any>({
        path: `/clients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerUploadClients
     * @request POST:/clients/upload-csv
     * @secure
     */
    clientsControllerUploadClients: (params: RequestParams = {}) =>
      this.request<any, Client>({
        path: `/clients/upload-csv`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerGetClient
     * @request GET:/clients/{id}
     * @secure
     */
    clientsControllerGetClient: (id: string, params: RequestParams = {}) =>
      this.request<any, Client>({
        path: `/clients/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerUpdate
     * @request PUT:/clients/{id}
     * @secure
     */
    clientsControllerUpdate: (id: string, data: UpdateClientDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerDeleteClient
     * @request DELETE:/clients/{id}
     * @secure
     */
    clientsControllerDeleteClient: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerAddDepartment
     * @request PUT:/clients/{id}/department
     * @secure
     */
    clientsControllerAddDepartment: (id: string, data: CreateDepartmentClientDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/department`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Clients
     * @name ClientsControllerDeleteDepartment
     * @request DELETE:/clients/{id}/department/{idDepartment}
     * @secure
     */
    clientsControllerDeleteDepartment: (id: string, idDepartment: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/department/${idDepartment}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  whatsapp = {
    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerSendMessage
     * @request POST:/whatsapp/{portal}/send-message
     * @secure
     */
    whatsappControllerSendMessage: (portal: string, data: SendMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/send-message`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerReceiveMessage
     * @request POST:/whatsapp/{portal}/receive-message
     */
    whatsappControllerReceiveMessage: (portal: string, data: ReceiveMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/receive-message`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Whatsapp
     * @name WhatsappControllerTestMessage
     * @request POST:/whatsapp/{portal}/test-message
     */
    whatsappControllerTestMessage: (portal: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/whatsapp/${portal}/test-message`,
        method: 'POST',
        ...params,
      }),
  };
  resumes = {
    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerCreate
     * @request POST:/resumes
     * @secure
     */
    resumesControllerCreate: (data: CreateResumeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/resumes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerGetAllResumes
     * @request GET:/resumes
     * @secure
     */
    resumesControllerGetAllResumes: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Resume[], any>({
        path: `/resumes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerGetResume
     * @request GET:/resumes/{id}
     * @secure
     */
    resumesControllerGetResume: (id: string, params: RequestParams = {}) =>
      this.request<any, Resume>({
        path: `/resumes/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Resumes
     * @name ResumesControllerDeleteResume
     * @request DELETE:/resumes/{id}
     * @secure
     */
    resumesControllerDeleteResume: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/resumes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  campaigns = {
    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerCreate
     * @request POST:/campaigns
     * @secure
     */
    campaignsControllerCreate: (data: CreateCampaignDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerFindAll
     * @request GET:/campaigns
     * @secure
     */
    campaignsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | Campaign[], any>({
        path: `/campaigns`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerFindOne
     * @request GET:/campaigns/{id}
     * @secure
     */
    campaignsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, Campaign>({
        path: `/campaigns/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerUpdate
     * @request PUT:/campaigns/{id}
     * @secure
     */
    campaignsControllerUpdate: (id: string, data: UpdateCampaignDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Campaigns
     * @name CampaignsControllerDelete
     * @request DELETE:/campaigns/{id}
     * @secure
     */
    campaignsControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaigns/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  campaignTemplates = {
    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerCreate
     * @request POST:/campaign-templates
     * @secure
     */
    campaignTemplatesControllerCreate: (data: CreateCampaignTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerFindAll
     * @request GET:/campaign-templates
     * @secure
     */
    campaignTemplatesControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | CampaignTemplate[], any>({
        path: `/campaign-templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerUpdate
     * @request PUT:/campaign-templates/{id}
     * @secure
     */
    campaignTemplatesControllerUpdate: (id: string, data: UpdateCampaignTemplateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CampaignTemplates
     * @name CampaignTemplatesControllerDelete
     * @request DELETE:/campaign-templates/{id}
     * @secure
     */
    campaignTemplatesControllerDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/campaign-templates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  company = {
    /**
     * No description
     *
     * @tags Company
     * @name CompanyControllerFindOne
     * @request GET:/company
     * @secure
     */
    companyControllerFindOne: (params: RequestParams = {}) =>
      this.request<any, Company>({
        path: `/company`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyControllerUpdate
     * @request PUT:/company
     * @secure
     */
    companyControllerUpdate: (data: CreateCompanyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/company`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  payrollExports = {
    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerGeneratePayrollExports
     * @request POST:/payrollExports
     * @secure
     */
    payrollExportsControllerGeneratePayrollExports: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payrollExports`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerFindAll
     * @request GET:/payrollExports
     * @secure
     */
    payrollExportsControllerFindAll: (
      query?: {
        /**
         * Page number
         * @example 1
         */
        page?: number;
        /**
         * Page size
         * @example 10
         */
        pageSize?: number;
        payrollId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginationResultDto | PayrollExport[], any>({
        path: `/payrollExports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerFindOne
     * @request GET:/payrollExports/{id}
     * @secure
     */
    payrollExportsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<any, PayrollExport>({
        path: `/payrollExports/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerGetPayrollExportSettings
     * @request GET:/payrollExports/settings/payroll-export
     * @secure
     */
    payrollExportsControllerGetPayrollExportSettings: (params: RequestParams = {}) =>
      this.request<any, PayrollExportSettings>({
        path: `/payrollExports/settings/payroll-export`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PayrollExports
     * @name PayrollExportsControllerUpdatePayrollExportSettings
     * @request PATCH:/payrollExports/settings
     * @secure
     */
    payrollExportsControllerUpdatePayrollExportSettings: (
      data: UpdateTimesheetSettingsDTO,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/payrollExports/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
