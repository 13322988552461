import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file/select-file-list.component';
import { SideModal } from '../../modals';
import { DocumentsListComponent } from '../../document/documents-list.component';

type EmploymentHistoryProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'cvFile' | 'references';

export const EmploymentHistory = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: EmploymentHistoryProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] | null }[]
  >([
    { key: 'cvFile', files: null },
    { key: 'references', files: [] },
  ]);
  const { getValues, setValue } = useForm<{
    cvFile: any[];
    references: any[];
  }>();

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);

      prev[itemIndex].files =
        key === 'cvFile'
          ? null
          : prev[itemIndex].files?.filter((el) => el._id !== id) || [];

      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // 5 MB file size limit
      if (selectedFile.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(`File size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`);
        return;
      }

      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      if (key === 'references') prev[itemIndex].files?.push(file);
      onUpdateCompliance(key, key === 'cvFile' ? file : prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'cvFile' | 'references',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    const values = getValues();
    onClickSubmit(compliance._id, values, false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      const cvFile = (compliance?.metadata as any)?.cvFile ?? null;
      const references = (compliance?.metadata as any)?.references ?? [];
      setValue('cvFile', cvFile);
      setValue('references', references);
      setFilesList([
        { key: 'cvFile', files: !Array.isArray(cvFile) ? [cvFile] : cvFile },
        { key: 'references', files: references },
      ]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      <TitleCard>Type of Employment</TitleCard>
      <div className="row upload-buttons-container">
        <div className="col-md-6">
          <FieldFile
            label="Upload CV"
            onChange={(e) => handleFileChange(e, 'cvFile')}
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => setIsModalOpen('cvFile')}
        >
          <Button type="primary">Select from files</Button>
        </div>
      </div>

      <div className="description">
        Max file size : 5MB. File format : pdf, docx, png, and jpeg <br />
        Does it contain current & most recent job. Is the CV going back in
        cronological order in context to work history and qualifications. Any
        time period gaps to be qualified and added to document. 
      </div>
      <DocumentsListComponent
        documents={filesList.find((el) => el.key === 'cvFile')?.files ?? []}
        onClickDelete={(id) => {
          onClickDelete('cvFile', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={(_) => {
          reFetchCompliance?.();
        }}
      />

      <TitleCard>CV Confirmation</TitleCard>
      <div className="description">
        Does the CV confirm full employment and training history inclusive of
        gaps 
      </div>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="cvConfirmation"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
      />
      <TitleCard>References</TitleCard>
      <div className="row upload-buttons-container">
        <div className="col-md-6">
          <FieldFile
            label="Upload References"
            onChange={(e) => handleFileChange(e, 'references')}
          />
        </div>
        <div
          className="col-md-6 button-select"
          onClick={() => setIsModalOpen('references')}
        >
          <Button type="primary">Select from files</Button>
        </div>
      </div>
      <div className="description">
        Max file size : 5MB. File format : pdf, docx, png, and jpeg <br />
        Referee details received? Do they cover the previous 3 years of
        employment or training. 
      </div>
      <DocumentsListComponent
        documents={filesList.find((el) => el.key === 'references')?.files ?? []}
        onClickDelete={(id) => {
          onClickDelete('references', id);
        }}
        getDocuments={getDocuments}
        onDocumentRename={(_id, fileName) => {
          reFetchCompliance?.();
        }}
      />
      <div className="info-card compliance-status buttons-container validated-by-ai">
        <Button
          type="primary"
          onClick={() => onClickSubmit(compliance._id, getValues(), true)}
        >
          Validate By AI
        </Button>
      </div>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
        />
      </SideModal>
    </>
  );
};
