import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  uploadUsersCsvRequest,
} from '../../utils';
import { User } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { Badge, Button, CardContainer, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { SideModal } from '../modals';
import { NewUserForm } from '../user';
import { UpdateUserForm } from '../user/update-user-form/update-user-form.component';
import { useAuth } from '../../contexts/auth.context';
import { UploadButton } from '../ui/buttons';

export enum EUserRole {
  ADMIN = 'admin',
  RECRUITER = 'recruiter',
  OFFICER = 'officer',
  RECRUITMENT_OFFICER = 'recruitment_officer',
}

type UsersListProps = {};

export const UsersList = ({}: UsersListProps) => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.ADMIN;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<User | null>(null);
  const [usersList, setUsersList] = useState<User[]>([]);

  const onClickEdit = (user: User) => {
    setEntityToUpdate(user);
  };

  const onClickDeleteUser = async (id: string) => {
    try {
      await AxiosInstance.users.usersControllerDeleteUser(id);
      toast.success('User deleted successfully');
      getUsers();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const uploadUsersCsv = async (file: File) => {
    await uploadUsersCsvRequest(file)
      .then(() => {
        getUsers();
        toast.success('Users uploaded successfully');
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <CardContainer className="right-container">
        <div className="settings-title-content">
          <TitlePage>Team Access</TitlePage>
          {isAdmin && (
            <div className="actions-container">
              <UploadButton accept=".csv" onUpload={uploadUsersCsv}>
                Upload
              </UploadButton>
              <Button type="primary" onClick={() => setIsModalOpen(true)}>
                <PlusIcon />
                Add New
              </Button>
            </div>
          )}
        </div>
        <hr />
        <div className="settings-content">
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <label>Team Member</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Email Address</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Role</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Active</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th className="action-th">
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {usersList.map((user) => (
                  <tr key={user._id}>
                    <td>
                      <div className="name-item">
                        {user?.firstName} {user?.lastName}
                      </div>
                    </td>
                    <td>{user?.email}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {user?.role}
                    </td>
                    <td>
                      <Badge text={'Active'} type={'success'} />
                    </td>
                    <td>
                      <div className="action-item">
                        <div
                          className={`edit-icon ${!isAdmin && 'disabled'}`}
                          onClick={() => isAdmin && onClickEdit(user)}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className={`delete-icon ${!isAdmin && 'disabled'}`}
                          onClick={() => isAdmin && onClickDeleteUser(user._id)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New User'}
      >
        <NewUserForm setIsOpen={setIsModalOpen} getUsers={getUsers} />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update User'}
      >
        <UpdateUserForm
          setIsOpen={() => setEntityToUpdate(null)}
          user={entityToUpdate as User}
          getUsers={getUsers}
        />
      </SideModal>
    </>
  );
};

export default UsersList;
